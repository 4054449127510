import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSignup } from "../../hooks/useSignup";
import CreateButton from "../../components/Buttons/CreateButton";
import DisabledButton from "../../components/Buttons/DisabledButton";
import LoadingButton from "../../components/Buttons/LoadingButton";
import {
  At,
  BuildingOffice,
  Password,
  SignIn,
  XCircle,
  CheckCircle,
  EyeSlash,
  Eye,
  Envelope,
} from "@phosphor-icons/react";

export default function SignupForm() {
  // State variables
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialChar: false,
    isLongEnough: false,
  });

  // Custom hook for signup
  const { signup, isPending, error } = useSignup();
  const navigate = useNavigate();

  // Password validation effect
  useEffect(() => {
    setPasswordValidation({
      hasUpperCase: /[A-Z]/.test(password),
      hasLowerCase: /[a-z]/.test(password),
      hasNumber: /[0-9]/.test(password),
      hasSpecialChar: /[!@#$%^&£~*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password),
      isLongEnough: password.length >= 8,
    });
  }, [password]);

  // Confirm password validation
  const isConfirmPasswordValid = isCPasswordDirty && password === cPassword;

  // Password validity check
  const isPasswordValid = Object.values(passwordValidation).every(Boolean);

  // Event handlers
  const handleCPassword = (e) => {
    setCPassword(e.target.value);
    setIsCPasswordDirty(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isPasswordValid && isConfirmPasswordValid) {
      try {
        const success = await signup(email, password, displayName);
        if (success) {
          localStorage.setItem("newSignupEmail", email);
          navigate("/verifyEmail");
        }
      } catch (err) {
        console.error("Error during signup:", err);
      }
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const closeModal = () => {
    setShowModal(false);
    navigate("/VerifyEmail");
  };

  // Helper function for password requirement rendering
  const renderPasswordRequirement = (isValid, text) => (
    <li className="flex items-center">
      <span className="mr-2 inline-flex items-center justify-center">
        {isValid ? (
          <CheckCircle size={25} weight="duotone" className="text-success fade-icon" />
        ) : (
          <XCircle size={25} weight="duotone" className="fade-icon" />
        )}
      </span>
      <span>{text}</span>
    </li>
  );

  return (
    <div className="text-center lg:text-left hero sm:min-h-full bg-base-100">
      <div className="hero-content flex-col lg:flex-row-reverse">
        {/* Left column */}
        <div className="pl-0 lg:pl-10 lg:w-1/2 lg:self-start lg:sticky lg:top-8 lg:mt-20">
          <div className="divider lg:divider-start divider-primary pageTitleSm">Sign Up</div>
          <p className="sm:py-5 w-full max-w-md">
            Please enter your Company or Trading name, email address and choose a password. 
            If you already have an account, please log in using the button below.
          </p>
          <div className="sm:hidden"><br /></div>
          <Link to="/Login">
            <button className="btn btn-wide btn-secondary">
              <SignIn size={25} />
              Log in to Your Account
            </button>
          </Link>
        </div>

        {/* Right column - Form */}
        <div className="card shrink-0 w-full max-w-md shadow-2xl bg-base-200" style={{ width: "448px" }}>
          <form className="card-body" onSubmit={handleSubmit}>
            {/* Company Name Input */}
            <div className="form-control pb-3">
              <label className="input input-bordered flex items-center gap-2">
                <BuildingOffice size={25} />
                <input
                  type="text"
                  spellCheck="false"
                  className="grow"
                  placeholder="Company Construction Ltd."
                  id="displayName"
                  onChange={(e) => setDisplayName(e.target.value)}
                  value={displayName}
                  required
                />
              </label>
            </div>

            {/* Email Input */}
            <div className="form-control pb-3">
              <div className="tooltip tooltip-warning" data-tip="This email address cannot be changed later.">
                <label className="input input-bordered flex items-center gap-2">
                  <At size={25} />
                  <input
                    type="email"
                    className="grow"
                    placeholder="Email"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                  />
                </label>
              </div>
            </div>

            {/* Password Input */}
            <div className="form-control pb-3">
              <label className="input input-bordered flex items-center gap-2 pe-0">
                <Password size={25} />
                <input
                  type={showPassword ? "text" : "password"}
                  required
                  className="grow"
                  placeholder="Password"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="btn btn-link text-base-content"
                >
                  {showPassword ? <EyeSlash size={25} /> : <Eye size={25} />}
                </button>
              </label>
            </div>

            {/* Confirm Password Input */}
            <div className="form-control pb-3">
              <label className={`input input-bordered flex items-center gap-2 pe-0 ${!isConfirmPasswordValid && isCPasswordDirty ? 'input-error' : ''}`}>
                <Password size={25} />
                <input
                  type={showPassword ? "text" : "password"}
                  required
                  className="grow"
                  placeholder="Confirm Password"
                  id="cPassword"
                  onChange={handleCPassword}
                  value={cPassword}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="btn btn-link text-base-content"
                >
                  {showPassword ? <EyeSlash size={25} /> : <Eye size={25} />}
                </button>
              </label>
              {!isConfirmPasswordValid && isCPasswordDirty && (
                <p className="text-error text-sm mt-1">Passwords do not match</p>
              )}
            </div>

            {/* Password requirements checklist */}
            <div className="text-sm mt-1">
              <div className="divider divider-primary passwordRules">Your password must contain:</div>
              <ul className="space-y-1">
                {renderPasswordRequirement(passwordValidation.hasUpperCase, "At least one uppercase letter.")}
                {renderPasswordRequirement(passwordValidation.hasLowerCase, "At least one lowercase letter.")}
                {renderPasswordRequirement(passwordValidation.hasNumber, "At least one number.")}
                {renderPasswordRequirement(passwordValidation.hasSpecialChar, "At least one special character.")}
                {renderPasswordRequirement(passwordValidation.isLongEnough, "At least eight characters in total.")}
              </ul>
            </div>

            {/* Submit Button */}
            <div className="submitbutton">
              {isPending ? (
                <LoadingButton />
              ) : isPasswordValid && isConfirmPasswordValid ? (
                <CreateButton />
              ) : (
                <DisabledButton />
              )}
            </div>

            {/* Error Message */}
            {error && (
              <div className="alert alert-error">
                <XCircle size={25} />
                <span>{error}</span>
              </div>
            )}
          </form>
        </div>
        <div className="block sm:hidden">
          <br />
          <br />
        </div>
      </div>

    </div>
  );
}