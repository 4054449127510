import { Link } from "react-router-dom"
import { useAuthContext } from '../../hooks/useAuthContext';

import NewEstimateForm from "../../components/NewEstimate/NewEstimateForm"

export default function NewEstimate() {
  const { user } = useAuthContext()

  return (
    <div className="bg-base-100 md:px-10 px-3">
      <div className="divider divider-primary md:px-5 max-sm:px-3 xl:px-20"><p className="pageTitle">New Estimate</p></div>
<div className="breadcrumbs text-md sticky top-28 sm:top-28 md:top-32 lg:top-36 bg-base-100 z-40 pb-5">
  <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/MyEstimates">My Estimates</Link></li>
        <li>New Estimate Details</li>
      </ul>
    </div>

    <div className="sm:hidden text-left pageSubheadingSm">
            New Estimate
          </div>
          <div className="max-sm:hidden text-left pageSubheading">
            New Estimate
          </div>
<div className="sm:hidden"><br/></div><br/>
<NewEstimateForm uid={user.uid} />
    </div>
  )
}
