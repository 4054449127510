import React, { useState, useEffect, useRef } from 'react';
import { ArrowClockwise, Envelope } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import { apersuAuthentication } from '../../firebase/config';
import { sendEmailVerification } from 'firebase/auth';

const VerifyEmail = () => {
  console.log("VerifyEmail component rendering");
  const navigate = useNavigate();
  const email = localStorage.getItem('newSignupEmail');
  const [resendDisabled, setResendDisabled] = useState(true);
  const [message, setMessage] = useState('');
  const [countdown, setCountdown] = useState(30);
  const [isCountingDown, setIsCountingDown] = useState(true);
  const [showCountdown, setShowCountdown] = useState(true);
  const [isExpanded, setIsExpanded] = useState(true);
  const timerRef = useRef(null);

  useEffect(() => {
    console.log("Initial useEffect running");
    startCountdown();
  }, []);

  const startCountdown = () => {
    console.log("startCountdown function called");
    setResendDisabled(true);
    setIsCountingDown(true);
    setCountdown(30);
    setIsExpanded(true);
    setTimeout(() => {
      setShowCountdown(true);
    }, 500); // Delay showing the timer by 0.5 seconds
  };

  useEffect(() => {
    console.log("Countdown useEffect running, countdown:", countdown, "isCountingDown:", isCountingDown);
    let timer;
    if (isCountingDown && countdown > 0) {
      timer = setTimeout(() => {
        console.log("Decreasing countdown to:", countdown - 1);
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      console.log("Countdown reached 0");
      setResendDisabled(false);
      setIsCountingDown(false);
      setShowCountdown(false);
      setTimeout(() => {
        setIsExpanded(false);
      }, 500); // Delay collapsing the container by 0.5 seconds
    }
    return () => clearTimeout(timer);
  }, [countdown, isCountingDown]);

  const handleResendEmail = async () => {
    console.log("handleResendEmail function called");
    if (resendDisabled) return;

    startCountdown();
    setMessage('');

    try {
      const user = apersuAuthentication.currentUser;
      if (user) {
        await sendEmailVerification(user);
        setMessage('Verification email sent successfully. Please check your inbox.');
      } else {
        setMessage('No user is currently signed in. Please sign in again.');
      }
    } catch (error) {
      console.error('Error sending verification email:', error);
      setMessage(`Error: ${error.message}`);
    }
  };

  const spinnerProgress = (countdown / 30) * 100;

  console.log("Rendering with showCountdown:", showCountdown, "countdown:", countdown, "isExpanded:", isExpanded);

  return (
    <div className="bg-base-100 md:px-10 px-4 my-0">
      <div className="divider divider-primary mt-16">
        <p className="pageTitle">Email Verification</p>
      </div>
      <div className="hero-content flex-col mx-auto pt-20">
        <div className={`card flex-shrink-0 w-full max-sm:max-w-sm max-w-xl shadow-2xl bordered border-4 bg-base-100 verify-email-card ${isExpanded ? 'expanded' : 'collapsed'}`}>
          <div className="card-body">
            <div className="text-center">
              <Envelope size={64} className="mx-auto mb-4 text-primary" />
              <h2 className="text-2xl font-bold mb-2">Verify Your Email</h2>
              <p className="mb-4">
                We've sent a verification email to:
                <br />
                <strong className="text-primary">{email}</strong>
              </p>
              <p className="mb-4">
                Please check your inbox and click on the verification link to complete your registration.
              </p>
              <button 
                onClick={handleResendEmail} 
                className={`btn btn-primary w-64 ${resendDisabled ? 'opacity-80 cursor-not-allowed' : ''}`}
                disabled={resendDisabled}
              >
                <ArrowClockwise size={25} />
                Resend Verification Email
              </button>
              <div 
                ref={timerRef}
                className={`cooldown-spinner-container ${showCountdown ? 'visible' : ''}`}
              >
                <div className="cooldown-spinner">
                  <svg className="spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                    <circle 
                      className="progress" 
                      cx="25" 
                      cy="25" 
                      r="20" 
                      fill="none" 
                      strokeWidth="5"
                      strokeDasharray={`${126 * spinnerProgress / 100} 126`}
                      transform="rotate(-90 25 25)"
                    ></circle>
                  </svg>
                  <div className="countdown">{countdown}</div>
                </div>
              </div>
              {/* {message && <p className="text-sm mt-2">{message}</p>} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;