import { CurrencyGbp, Files, UserRectangle, Wrench } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'
export default function MDCards() {
  return (<>
<div className="xl:hidden lg:hidden max-sm:hidden grid grid-cols-1 grid-rows-4 gap-4">


<div className="card card-side bg-base-100 hover:ring hover:ring-primary shadow-xl">
  <figure>
    <img
      src="https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp"
      alt="Movie" />
  </figure>
  <div className="card-body">
    <h2 className="cardTitle divider divider-start divider-primary">My Profile</h2>
    <p className='cardText'>View and adjust your <br/>profile information.</p>
    <div className="card-actions justify-end">
    <Link to="/MyProfile"><button className="btn btn-wide btn-primary"><UserRectangle size={25}/>My Profile</button></Link>
    </div>
  </div>
</div>


<div className="card card-side bg-base-100 hover:ring hover:ring-primary shadow-xl">
  <figure>
    <img
      src="https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp"
      alt="Movie" />
  </figure>
  <div className="card-body">
    <h2 className="cardTitle divider divider-start divider-primary">My Estimates</h2>
    <p className='cardText'>View your estimates and <br/>submit new projects.</p>
    <div className="card-actions justify-end">
    <Link to="/MyEstimates"><button className="btn btn-wide btn-primary"><Files size={25}/>My Estimates</button></Link>
    </div>
  </div>
</div>


<div className="card card-side bg-base-100 hover:ring hover:ring-primary shadow-xl">
  <figure>
    <img
      src="https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp"
      alt="Movie" />
  </figure>
  <div className="card-body">
    <h2 className="cardTitle divider divider-start divider-primary">Labour Rates</h2>
    <p className='cardText'>View and adjust labour rates.</p>
    <div className="card-actions justify-end">
    <Link to="/LabourRates"><button className="btn btn-wide btn-primary"><Wrench size={25}/>Adjust Labour Rates</button></Link>
    </div>
  </div>
</div>


<div className="card card-side bg-base-100 hover:ring hover:ring-primary shadow-xl">
  <figure>
    <img
      src="https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp"
      alt="Movie" />
  </figure>
  <div className="card-body">
    <h2 className="cardTitle divider divider-start divider-primary">Material Costs</h2>
    <p className='cardText'>View and adjust <br/>material costs.</p>
    <div className="card-actions justify-end">
    <Link to="/MaterialCosts"><button className="btn btn-wide btn-primary"><CurrencyGbp size={25}/>Adjust Material Costs</button></Link>
    </div>
  </div>
</div>

</div><br/><br/>
  </>)
}
