import React, { useState, useRef, useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import {
  PhoneAuthProvider,
  multiFactor,
  PhoneMultiFactorGenerator,
  EmailAuthProvider,
  reauthenticateWithCredential,
  RecaptchaVerifier,
  getMultiFactorResolver,
  getAuth,
} from "firebase/auth";
import { apersuAuthentication } from "../firebase/config";
import {
  Shield,
  SealCheck,
  ShieldCheck,
  ShieldWarning,
  XCircle,
  CaretCircleRight,
  CaretLeft,
} from "@phosphor-icons/react";
import LoadingButton from "../components/Buttons/LoadingButton"; // Adjust the import path as needed

const MFAManager = ({ onClose, isMFAEnabled, setIsMFAEnabled }) => {
  const [currentStep, setCurrentStep] = useState(
    isMFAEnabled ? "mfaEnabled" : "intro"
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(30);
  const [closeCountdown, setCloseCountdown] = useState(0);
  const [mfaResolver, setMfaResolver] = useState(null);
  const [isRecaptchaVerifying, setIsRecaptchaVerifying] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [reauthPassword, setReauthPassword] = useState("");

  const { user, dispatch } = useAuthContext();
  const auth = getAuth();
  const verificationInputRefs = useRef([]);
  const recaptchaVerifier = useRef(null);

  useEffect(() => {
    if (!recaptchaVerifier.current) {
      recaptchaVerifier.current = new RecaptchaVerifier(
        apersuAuthentication,
        "recaptcha-container",
        {
          size: "invisible",
        }
      );
    }
  }, []);

  useEffect(() => {
    let timer;
    if (resendDisabled) {
      timer = setInterval(() => {
        setResendCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setResendDisabled(false);
            return 30;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendDisabled]);

  useEffect(() => {
    let timer;
    if (closeCountdown > 0) {
      timer = setInterval(() => {
        setCloseCountdown((prev) => {
          if (prev <= 0) {
            clearInterval(timer);
            return 0;
          }
          return prev - 0.01; // Decrease countdown by 0.01
        });
      }, 10); // Run every 10ms for very smooth animation
    }
  
    return () => clearInterval(timer);
  }, [closeCountdown]);
  

  const formatPhoneNumber = (number) => {
    const cleaned = number.replace(/\D/g, "");
    if (cleaned.startsWith("07") && cleaned.length === 11) {
      return "+44" + cleaned.slice(1);
    }
    return null;
  };

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    const formattedNumber = formatPhoneNumber(phoneNumber);
    if (!formattedNumber) {
      setError("Please enter a valid UK mobile number starting with 07.");
      setIsLoading(false);
      return;
    }

    try {
      const session = await multiFactor(user).getSession();
      const phoneAuthProvider = new PhoneAuthProvider(apersuAuthentication);
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        { phoneNumber: formattedNumber, session },
        recaptchaVerifier.current
      );
      setVerificationId(verificationId);
      setCurrentStep("verifyCode");
      setResendDisabled(true);
    } catch (error) {
      handleFirebaseError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleVerifySubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    const code = verificationCode.join("");
    if (code.length !== 6) {
      setError("Please enter a valid 6-digit code.");
      setIsLoading(false);
      return;
    }

    try {
      const cred = PhoneAuthProvider.credential(verificationId, code);
      await multiFactor(user).enroll(PhoneMultiFactorGenerator.assertion(cred));
      setSuccess("MFA has been successfully enabled.");
      setIsMFAEnabled(true);
      setCloseCountdown(2);
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      handleFirebaseError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDisableMFA = async () => {
    setError("");
    setIsLoading(true);
  
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) throw new Error("User not found");
  
      // Step 1: Refresh the user's MFA state
      await currentUser.reload(); // Ensures latest data from Firebase
  
      // Step 2: Check for enrolled MFA factors
      const enrolledFactors = currentUser.multiFactor?.enrolledFactors;
      if (!enrolledFactors || enrolledFactors.length === 0) {
        setSuccess("MFA is already disabled.");
        setIsMFAEnabled(false);
        setTimeout(onClose, 2000); // Auto-close after 2 seconds
        return;
      }
  
      console.log("MFA enrolled factors before unenrollment:", enrolledFactors);
  
      // Step 3: Unenroll from the first factor (or any factor)
      const unenrollResponse = await multiFactor(currentUser).unenroll(enrolledFactors[0].uid);
      console.log("Unenroll response:", unenrollResponse); // Log the unenroll response
  
      // Step 4: Force refresh token and reload user data to ensure changes apply
      await currentUser.getIdToken(true);
      await currentUser.reload();
      console.log("User reloaded after unenrollment");
  
      // Step 5: Recheck for enrolled factors to confirm MFA is disabled
      const updatedEnrolledFactors = currentUser.multiFactor?.enrolledFactors;
      if (!updatedEnrolledFactors || updatedEnrolledFactors.length === 0) {
        // MFA is now disabled, proceed with success
        dispatch({ type: "LOGIN", payload: currentUser });
        setSuccess("MFA has been successfully disabled.");
        setIsMFAEnabled(false);
      } else {
        // If MFA factors still exist, raise an error
        throw new Error("MFA was not successfully disabled. Retrying...");
      }
  
    } catch (error) {
      console.error("Error in handleDisableMFA:", error);
      
      // Retry logic in case MFA isn't disabled in the first attempt
      if (error.message.includes("MFA was not successfully disabled")) {
        console.log("Retrying MFA unenrollment...");
        
        setTimeout(async () => {
          // Retry the whole process after a delay
          await handleDisableMFA();
        }, 3000); // Retry after 3 seconds
      } else {
        handleFirebaseError(error); // For other errors
      }
  
    } finally {
      setIsLoading(false);
      setTimeout(onClose, 2000); // Auto-close after 2 seconds
    }
  };
  const handleReauthenticate = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      const credential = EmailAuthProvider.credential(currentUser.email, reauthPassword);
      
      console.log("Attempting to reauthenticate user");
      await reauthenticateWithCredential(currentUser, credential);
      console.log("User reauthenticated successfully");
      
      await handleDisableMFA();
    } catch (error) {
      console.error("Error in handleReauthenticate:", error);
      handleFirebaseError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    setIsRecaptchaVerifying(true);

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(user.email, password);
      
      console.log("Attempting to reauthenticate user");
      await reauthenticateWithCredential(user, credential);
      console.log("User reauthenticated successfully");
      
      setIsRecaptchaVerifying(false);
      await handleDisableMFA();
    } catch (error) {
      console.error("Error in handlePasswordSubmit:", error);
      if (error.code === "auth/multi-factor-auth-required") {
        console.log("MFA is required, handling MFA challenge");
        const resolver = getMultiFactorResolver(auth, error);
        setMfaResolver(resolver);
        await handleMfaChallenge(resolver);
      } else {
        handleFirebaseError(error);
      }
    } finally {
      setIsLoading(false);
      setIsRecaptchaVerifying(false);
    }
  };
  const handleMfaChallenge = async (resolver) => {
    try {
      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session,
      };
      const phoneAuthProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier.current
      );
      setVerificationId(verificationId);
      setCurrentStep("enterMfaCode");
    } catch (error) {
      console.error("Error in handleMfaChallenge:", error);
      handleFirebaseError(error);
    }
  };
  const handleMfaCodeSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const code = verificationCode.join("");
      const cred = PhoneAuthProvider.credential(verificationId, code);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await mfaResolver.resolveSignIn(multiFactorAssertion);

      console.log("MFA resolved successfully, proceeding to disable MFA");
      await handleDisableMFA();
    } catch (error) {
      console.error("Error in handleMfaCodeSubmit:", error);
      handleFirebaseError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleFirebaseError = (error) => {
    console.error("Firebase error:", error);
    /* 
    Error handling:
    - auth/requires-recent-login: User needs to re-authenticate for security reasons
    - auth/invalid-verification-code: The provided SMS code is incorrect
    - auth/invalid-phone-number: The phone number format is invalid
    - auth/wrong-password: The provided password is incorrect
    - auth/too-many-requests: Too many unsuccessful attempts, suggesting a potential security threat
    - default: A generic error message for unhandled errors
    */
    switch (error.code) {
      case "auth/requires-recent-login":
        setError(
          "For security reasons, please re-enter your password to continue."
        );
        setCurrentStep("reauthenticate");
        break;
      case "auth/invalid-verification-code":
        setError(
          "The verification code you entered is invalid. Please check and try again."
        );
        break;
      case "auth/invalid-phone-number":
        setError(
          "The phone number you entered is invalid. Please enter a valid UK mobile number starting with 07."
        );
        break;
      case "auth/wrong-password":
        setError("The password you entered is incorrect. Please try again.");
        break;
      case "auth/too-many-requests":
        setError(
          "Too many unsuccessful attempts. For security reasons, please try again later."
        );
        break;
      default:
        setError(
          "An unexpected error occurred. Please try again later or contact support if the problem persists."
        );
    }
  };
  const handleVerificationCodeChange = (index, value) => {
    const newVerificationCode = [...verificationCode];
    newVerificationCode[index] = value;
    setVerificationCode(newVerificationCode);

    if (value && index < 5) {
      verificationInputRefs.current[index + 1].focus();
    } else if (!value && index > 0) {
      verificationInputRefs.current[index - 1].focus();
    }
  };
  const handleResendCode = async () => {
    setResendDisabled(true);
    setError("");
    try {
      const session = await multiFactor(user).getSession();
      const phoneAuthProvider = new PhoneAuthProvider(apersuAuthentication);
      const newVerificationId = await phoneAuthProvider.verifyPhoneNumber(
        { phoneNumber: formatPhoneNumber(phoneNumber), session },
        recaptchaVerifier.current
      );
      setVerificationId(newVerificationId);
      setSuccess("Verification code resent successfully.");
    } catch (error) {
      handleFirebaseError(error);
    }
  };
  const renderStep = () => {
    const commonClasses =
      "flex flex-col items-center justify-center h-64 space-y-4";

    switch (currentStep) {
      case "intro":
        return (
          <div className={commonClasses}>
            <h2 className="text-2xl font-bold">Multi-Factor Authentication</h2>
            <p className="text-center">
              MFA adds an extra layer of security to your account by requiring a
              second form of verification.
            </p>
            {isLoading ? (
              <LoadingButton />
            ) : (
              <button
                onClick={() => setCurrentStep("enterPhone")}
                className="btn btn-primary"
                disabled={isLoading}
              >
                <CaretCircleRight size={25} />
                Set up MFA
              </button>
            )}
          </div>
        );
      case "mfaEnabled":
        return (
          <div className={commonClasses}>
            <h2 className="text-2xl font-bold">MFA Options</h2>
            <p>
              Your account is currently protected with Multi-Factor
              Authentication.
            </p>
            {isLoading ? (
              <LoadingButton />
            ) : (
              <>
                <button
                  onClick={() => setCurrentStep("confirmDisable")}
                  className="btn btn-secondary"
                  disabled={isLoading}
                >
                  <ShieldWarning size={25} />
                  Disable MFA
                </button>
                <button onClick={onClose} className="btn btn-primary">
                  Cancel
                </button>
              </>
            )}
          </div>
        );
      case "confirmDisable":
        return (
          <div className={commonClasses}>
            <h2 className="text-2xl font-bold">Disable MFA</h2>
            <p>Are you sure you want to disable Multi-Factor Authentication?</p>
            <div className="flex space-x-4">
              {isLoading ? (
                <LoadingButton />
              ) : (
                <>
                  <button
                    onClick={() => setCurrentStep("enterPassword")}
                    className="btn btn-secondary"
                    disabled={isLoading}
                  >
                    Yes, Disable MFA
                  </button>
                  <button
                    onClick={() => setCurrentStep("mfaEnabled")}
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        );
      case "enterPassword":
        return (
          <form onSubmit={handlePasswordSubmit} className={commonClasses}>
            <h2 className="text-2xl font-bold">Confirm Password</h2>
            <p className="text-center">
              Please enter your password to disable MFA.
            </p>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              className="input input-bordered w-full max-w-xs"
              required
            />
            {isLoading ? (
              <LoadingButton />
            ) : (
              <>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isLoading}
                >
                  Confirm and Disable MFA
                </button>
                <button
                  type="button"
                  onClick={() => setCurrentStep("mfaEnabled")}
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
              </>
            )}
            {isRecaptchaVerifying && (
              <p className="text-sm text-gray-600">
                Verifying... This may take a moment.
              </p>
            )}
          </form>
        );
      case "enterPhone":
        return (
          <form onSubmit={handlePhoneSubmit} className={commonClasses}>
            <h2 className="text-2xl font-bold">Enter Your Phone Number</h2>
            <p className="text-center">
              Please enter your UK mobile number starting with 07.
            </p>
            <input
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="07XXX XXX XXX"
              className="input input-bordered w-full max-w-xs"
              required
            />
            {isLoading ? (
              <LoadingButton />
            ) : (
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
              >
                <CaretCircleRight size={25} />
                Send Verification Code
              </button>
            )}
          </form>
        );
      case "verifyCode":
        return (
          <form onSubmit={handleVerifySubmit} className={commonClasses}>
            <h2 className="text-2xl font-bold">Enter Verification Code</h2>
            <p className="text-center">
              Please enter the 6-digit code sent to your phone.
            </p>
            <div className="join">
              {verificationCode.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) =>
                    handleVerificationCodeChange(index, e.target.value)
                  }
                  ref={(el) => (verificationInputRefs.current[index] = el)}
                  className="join-item w-10 h-10 text-center input input-bordered text-xl p-0"
                  required
                />
              ))}
            </div>
            <div className="flex space-x-4">
              {isLoading ? (
                <LoadingButton />
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    Verify Code
                  </button>
                  <button
                    type="button"
                    onClick={handleResendCode}
                    className="btn btn-secondary"
                    disabled={resendDisabled || isLoading}
                  >
                    {resendDisabled
                      ? `Resend (${resendCountdown}s)`
                      : "Resend Code"}
                  </button>
                </>
              )}
            </div>
            <button
              type="button"
              onClick={() => setCurrentStep("enterPhone")}
              className="btn btn-link"
            >
              <CaretLeft size={20} />
              Change Phone Number
            </button>
          </form>
        );
      case "reauthenticate":
        return (
          <form onSubmit={handleReauthenticate} className={commonClasses}>
            <h2 className="text-2xl font-bold">Re-authenticate</h2>
            <p className="text-center">
              For security reasons, please enter your password to continue.
            </p>
            <input
              type="password"
              value={reauthPassword}
              onChange={(e) => setReauthPassword(e.target.value)}
              placeholder="Enter your password"
              className="input input-bordered w-full max-w-xs"
              required
              autoComplete="current-password"
            />
            {isLoading ? (
              <LoadingButton />
            ) : (
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
              >
                Confirm
              </button>
            )}
          </form>
        );
      case "enterMfaCode":
        return (
          <form onSubmit={handleMfaCodeSubmit} className={commonClasses}>
            <h2 className="text-2xl font-bold">Enter MFA Code</h2>
            <p className="text-center">
              Please enter the 6-digit code sent to your phone.
            </p>
            <div className="join">
              {verificationCode.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) =>
                    handleVerificationCodeChange(index, e.target.value)
                  }
                  ref={(el) => (verificationInputRefs.current[index] = el)}
                  className="join-item w-10 h-10 text-center input input-bordered text-xl p-0"
                  required
                />
              ))}
            </div>
            {isLoading ? (
              <LoadingButton />
            ) : (
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
              >
                Verify and Disable MFA
              </button>
            )}
          </form>
        );
      default:
        return null;
    }
  };

  return (
    <div className="mfa-manager">
      <div className="transition-opacity duration-300 ease-in-out min-h-[300px] flex flex-col justify-between">
        <div className="flex-grow flex items-center justify-center">
          {renderStep()}
        </div>
        <div className="h-16 flex items-center justify-center">
          {error && <p className="error-message text-red-500">{error}</p>}
          {success && (
            <p className="success-message text-green-500">{success}</p>
          )}
        </div>
        {closeCountdown > 0 && (
          <div className="w-full">
            <progress
              className="progress progress-primary w-full"
              value={2 - closeCountdown}
              max="2"
            ></progress>
          </div>
        )}
      </div>
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default MFAManager;
