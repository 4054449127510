import { Link } from "react-router-dom";
import CompanyInfoForm from "../../components/ProfileInfo/CompanyInfoForm";
import CompanyLogoCard from "../../components/ProfileInfo/CompanyLogoCard";
import ValidateEmail from "../../components/ProfileInfo/ValidateEmail";

export default function MyProfile({ user, uid }) {
  return (
    <div className="bg-base-100 md:px-10 px-3">
      
      <div className="divider divider-primary md:px-5 max-sm:px-3 xl:px-20">
        <p className="pageTitle">My Profile</p>
      </div>
      <div className="breadcrumbs text-md sticky top-28 sm:top-28 md:top-32 lg:top-36 bg-base-100 z-40 pb-5">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>My Profile</li>
        </ul>
      </div>
      <div className="sm:hidden text-left pageSubheadingSm">My Profile</div>
      <div className="max-sm:hidden text-left pageSubheading">My Profile</div>
      {!user.emailVerified && (
        <>
          <div className="pageSubtitle text-left max-sm:ps-6 pb-5 ps-20">
            Please verify your email address to update your profile information.
            <br />
            If you haven't received the verification email, you can resend it
            using the button below.
            <br />
          </div>
          <ValidateEmail />
        </>
      )}
      {user.emailVerified && (
        <>
          <CompanyInfoForm />
          <br />
          <CompanyLogoCard user uid={user.uid} profilePic={user.photoUrl} />
        </>
      )}
      <br />
      <br />
      <br />
    </div>
  );
}
