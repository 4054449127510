import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext';
//styles
import './App.css';
import './output.css';
//pages and components
import Navbar from './components/NavBar/Navbar';
import Login from './pages/Login';
import Signup from './pages/Signup'
import Dashboard from './pages/User/Dashboard';
import LabourRates from './pages/User/LabourRates';
import MaterialCosts from './pages/User/MaterialCosts';
import MyProfile from './pages/User/MyProfile';
import MyEstimates from './pages/User/MyEstimates';
import Estimate from './pages/User/Estimate';
import NewEstimate from './pages/User/NewEstimate';
import Footer from './components/Footer/Footer';
import AuthActions from './pages/Auth/AuthActions';
import VerifyEmail from './pages/Auth/VerifyEmail';

function App() {
  const { authIsReady, user } = useAuthContext()

    
  useEffect(() => {
    console.log('App rendered. authIsReady:', authIsReady, 'user:', user);
  }, [authIsReady, user]);

  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={user ? <Dashboard user={user} uid={user.uid} /> : <Navigate to="/Login" />} />
            <Route path="/MyProfile" element={user ? <MyProfile user={user} uid={user.uid} /> : <Navigate to="/Login" />} />
            <Route path="/MyEstimates" element={user ? <MyEstimates user={user} uid={user.uid} /> : <Navigate to="/Login" />} />
            <Route path="/LabourRates" element={user ? <LabourRates user={user} uid={user.uid} /> : <Navigate to="/Login" />} />
            <Route path="/MaterialCosts" element={user ? <MaterialCosts user={user} uid={user.uid} /> : <Navigate to="/Login" />} />
            <Route path="/MyEstimates/Estimate/:id" element={user ? <Estimate user={user} uid={user.uid} /> : <Navigate to="/Login" />} />
            <Route path="/MyEstimates/NewEstimate" element={user ? <NewEstimate user={user} uid={user.uid} /> : <Navigate to="/Login" />} />
            <Route path="/Signup" element={!user ? <Signup /> : <Navigate to="/" />} />
            <Route path="/Login" element={!user ? <Login /> : <Navigate to="/" />} />
            <Route path="/Auth" element={<AuthActions />} /> 
            <Route path="/verifyEmail" element={user && !user.emailVerified ? <VerifyEmail /> : <Navigate to="/" />} />
            {/* <Route path="/verifyEmail" element={!user && <VerifyEmail /> }/> */}


          </Routes>
          <Footer />
        </BrowserRouter>
      )}
    </div>
  );
}
export default App;