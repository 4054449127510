import { Link, useParams } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { apersuDatabase } from '../../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import Loading from "../../components/Loading";

export default function Estimate({ uid }) {
  const { id } = useParams();

  const [estimate, setEstimate] = useState(null);
  const [projectFiles, setProjectFiles] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);

  const fetchEstimate = useCallback(async () => {
    setIsPending(true);
    const docRef = doc(apersuDatabase, `users/${uid}/estimates/`, id);

    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const estimateData = { ...docSnap.data(), id: docSnap.id };
        setEstimate(estimateData);
        setProjectFiles(estimateData.projectFiles || []);
        setIsPending(false);
      } else {
        setIsPending(false);
        setError("This Estimate does not exist, or you don't have permission to view it. Please head back to your Estimate List and try again.");
      }
    } catch (err) {
      setIsPending(false);
      setError("An error occurred while fetching the estimate.");
    }
  }, [uid, id]);

  useEffect(() => {
    fetchEstimate();
  }, [fetchEstimate]);

  const openProjectFile = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="md:px-10 px-3">
      {estimate &&
        <div className="divider divider-primary md:px-5 max-sm:px-3 xl:px-20">
          <p className="pageTitle">{estimate.projectAddressLine1}, {estimate.projectFormattedPostcode}</p>
        </div>
      }
      {!estimate && !isPending && !error &&
        <div className="divider divider-primary md:px-5 max-sm:px-3 xl:px-20">
          <p className="pageTitle noEstimatesErrorText">Estimate Not Found</p>
        </div>
      }

      <div className="breadcrumbs text-md sticky top-28 sm:top-28 md:top-32 lg:top-36 bg-base-100 z-40 pb-5">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/MyEstimates">My Estimates</Link></li>
          {estimate &&
            <li>{estimate.projectAddressLine1}, {estimate.projectFormattedPostcode}</li>
          }
          {!estimate && !isPending && !error &&
            <li className="noEstimatesErrorText">Estimate Not Found</li>
          }
        </ul>
      </div>
      {isPending && <Loading />}
      {estimate && <>
        <div className="tooltip tooltip-bottom" data-tip={estimate.projectStatusTooltip}>
          <div className={`badge badge-xl xl:w-32 sm:w-28 badge-${estimate.projectStatusBadge}`}>
            <div>{estimate.projectStatus}</div>
          </div>
        </div>

      <div className="card bg-base-200 w-11/12 mx-auto md:p-8 p-3 shadow-xl mt-5 mb-16">
        <div className="sm:hidden text-left">
        <div className="divider divider-start divider-primary estimateDetailTitleSm pb-2">Project Reference: {estimate.projectNumber}</div>
        <div className="estimateDetailBodySm">
            {estimate.projectSplitProposal} at:<br />
            {estimate.projectAddressLine1},
            {estimate.projectAddressLine2 && <><br />{estimate.projectAddressLine2},</>}
            {estimate.projectAddressLine3 && <><br />{estimate.projectAddressLine3},</>}
            <br />
            {estimate.projectFormattedPostcode}.
            <br /><br />
            Contract Value: £{estimate.projectValue}<br />
          <p>{estimate.projectNotes}</p>
          </div>
        </div>

        <div className="max-sm:hidden text-left">
        <div className="divider divider-start divider-primary estimateDetailTitle pb-5">Project Reference: {estimate.projectNumber}</div>
        <div className="estimateDetailBody">
            {estimate.projectSplitProposal} at:<br />
            {estimate.projectAddressLine1},
            {estimate.projectAddressLine2 && <><br />{estimate.projectAddressLine2},</>}
            {estimate.projectAddressLine3 && <><br />{estimate.projectAddressLine3},</>}
            <br />
            {estimate.projectFormattedPostcode}.
            <br /><br />
            Contract Value: £{estimate.projectValue}<br />
          <p>{estimate.projectNotes}</p>
          </div>
        </div>
        {projectFiles.length > 0 && (
              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2">Project Documents:</h3>
                {projectFiles.map((file, index) => (
                  <button
                    key={index}
                    className="btn btn-primary mr-2 mb-2"
                    onClick={() => openProjectFile(file.url)}
                  >
                    {file.name}
                  </button>
                ))}
              </div>
            )}
        </div>
      </>}

      {error && <>
        <div className="sm:hidden text-left">
          <h1>{error} Please head back to your <Link className="blueLink" to="/MyEstimates"><u>Estimate List</u></Link> and try again.</h1>
        </div>
        <div className="max-sm:hidden text-left">
          <h2>{error} Please head back to your <Link className="blueLink" to="/MyEstimates"><u>Estimate List</u></Link> and try again.</h2>
        </div>
      </>}
      <div className="sm:hidden"><br /></div><br /><br />
    </div>
  );
}
