import { useState, useCallback } from "react";
import { apersuAuthentication } from "../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuthContext } from "./useAuthContext";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();

  const login = useCallback(async (email, password) => {
    setError(null);
    setIsPending(true);
    console.log("Login attempt started for email:", email);

    if (!email || !password) {
      console.log("Empty fields detected");
      setError("EMPTY_FIELDS");
      setIsPending(false);
      return;
    }

    try {
      console.log("Attempting to sign in with Firebase");
      const res = await signInWithEmailAndPassword(apersuAuthentication, email, password);
      console.log("Login successful", res.user);

      dispatch({ type: "LOGIN", payload: res.user });
      console.log("Dispatched LOGIN action");

      setIsPending(false);
    } catch (err) {
      console.error("Login error caught:", err.code, err.message);
      
      let errorType;
      switch(err.code) {
        case "auth/invalid-credential":
          errorType = "INVALID_CREDENTIALS";
          console.log("Error set: INVALID_CREDENTIALS (could be wrong email or password)");
          break;
        case "auth/invalid-email":
          errorType = "INVALID_EMAIL";
          console.log("Error set: INVALID_EMAIL");
          break;
        case "auth/user-disabled":
          errorType = "USER_DISABLED";
          console.log("Error set: USER_DISABLED");
          break;
        case "auth/too-many-requests":
          errorType = "TOO_MANY_REQUESTS";
          console.log("Error set: TOO_MANY_REQUESTS");
          break;
        case "auth/network-request-failed":
          errorType = "NETWORK_ERROR";
          console.log("Error set: NETWORK_ERROR");
          break;
        default:
          errorType = "DEFAULT";
          console.log("Error set: DEFAULT");
          console.error("Unexpected error during login:", err);
      }

      console.log("Setting error state to:", errorType);
      setError(errorType);
      setIsPending(false);
    }
  }, [dispatch]);

  const clearError = useCallback(() => {
    console.log("Clearing error state");
    setError(null);
  }, []);

  return { login, error, isPending, clearError };
};