import { Envelope, SealCheck } from "@phosphor-icons/react";
import React, { useState } from "react";
import { useValidate } from "../../hooks/useValidate";
import { useAuthContext } from "../../hooks/useAuthContext";

const ValidateEmail = () => {
  const { sendEmailValidation, isPending, error } = useValidate();
  const { user } = useAuthContext();
  const [showModal, setShowModal] = useState(false);

  const handleResendValidation = async () => {
    const success = await sendEmailValidation();
    if (success) {
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <button
        className="btn btn-wide btn-primary"
        disabled={isPending}
        onClick={handleResendValidation}>
        <SealCheck size={25} /> Resend Verification Email
      </button>
      {isPending && <p>Sending verification email...</p>}
      {error && <p className="error">{error}</p>}

      {showModal && (
        <dialog open className="modal">
          <div className="modal-box">
            <h3 className="font-bold text-lg">
              <Envelope size={35} /> Check Your Inbox
            </h3>
            <p className="py-4">
              A new verification email has been sent to your email address (
              {user?.email}). Please check your inbox and click the verification
              link to complete the process.
            </p>
            <div className="modal-action">
              <button className="btn btn-primary" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
          <form method="dialog" className="modal-backdrop">
            <button onClick={closeModal}>close</button>
          </form>
        </dialog>
      )}
    </>
  );
};

export default ValidateEmail;
