import { CurrencyGbp, Files, UserRectangle, Wrench } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'
export default function XLCards() {
  return (<>
<div className="max-xl:hidden grid grid-cols-2 grid-rows-2 gap-4 pt-5">
<div className="card xl:card-side bg-base-200 hover:bg-base-100 hover:ring hover:ring-primary shadow-xl">
<figure className='cardImageDiv'>
    <img
      className='cardImage'
      src="https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp"
      alt="Album" />
  </figure>
  <div className="card-body w-8/12">
    <div className="lgcardTitle divider divider-start divider-primary">My Profile</div>
    <p className='lgcardText'>
      View and adjust your profile information.
    </p>
    <div className="card-actions justify-end">
      <Link to="/MyProfile"><button className="btn btn-wide btn-primary"><UserRectangle size={25}/>My Profile</button></Link>
    </div>
  </div>
</div>
<div className="card xl:card-side bg-base-200 hover:bg-base-100 hover:ring hover:ring-primary shadow-xl">
<figure className='cardImageDiv'>
    <img
      className='cardImage'
      src="https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp"
      alt="Album" />
  </figure>
  <div className="card-body w-8/12">
    <div className="lgcardTitle divider divider-start divider-primary">My Estimates</div>
    <p className='lgcardText'>
    View your estimates and submit new projects.
    </p>
    <div className="card-actions justify-end">
      <Link to="/MyEstimates"><button className="btn btn-wide btn-primary"><Files size={25}/>My Estimates</button></Link>
    </div>
  </div>
</div>
<div className="card xl:card-side bg-base-200 hover:bg-base-100 hover:ring hover:ring-primary shadow-xl">
<figure className='cardImageDiv'>
    <img
      className='cardImage'
      src="https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp"
      alt="Album" />
  </figure>
  <div className="card-body w-8/12">
    <div className="lgcardTitle divider divider-start divider-primary">Labour Rates</div>
    <p className='lgcardText'>
    View and adjust labour rates.
    </p>
    <div className="card-actions justify-end">
      <Link to="/LabourRates"><button className="btn btn-wide btn-primary"><Wrench size={25}/>Adjust Labour Rates</button></Link>
    </div>
  </div>
</div>

<div className="card xl:card-side bg-base-200 hover:bg-base-100 hover:ring hover:ring-primary shadow-xl">
  <figure className='cardImageDiv'>
    <img
      className='cardImage'
      src="https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp"
      alt="Album" />
  </figure>
  <div className="card-body w-8/12">
    <div className="lgcardTitle divider divider-start divider-primary">Material Costs</div>
    <p className='lgcardText'>
    View and adjust material costs.
    </p>
    <div className="card-actions justify-end">
      <Link to="/MaterialCosts"><button className="btn btn-wide btn-primary"><CurrencyGbp size={25}/>Adjust Material Costs</button></Link>
    </div>
  </div>
</div>
</div>

<br/>
<br/>
  </>)
}
