import { useState } from 'react'
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from 'firebase/auth'
import { apersuAuthentication } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useSignup = () => {
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const signup = async (email, password, displayName) => {
    setError(null)
    setIsPending(true)
  
    try {
      // signup
      const res = await createUserWithEmailAndPassword(apersuAuthentication, email, password)

      if (!res) {
        throw new Error('Could not complete signup')
      }

      // add display name to user
      await updateProfile(res.user, { displayName })

      // send verification email
      await sendEmailVerification(res.user)

      // dispatch login action
      dispatch({ type: 'LOGIN', payload: res.user })

      setIsPending(false)
      setError(null)
      
      console.log('Signup successful, verification email sent');
      return true  // Return true to indicate success
    } 
    catch(err) {
      console.log(err.message)
      if (err.code === 'auth/email-already-in-use') {
        setError('This email address is already associated with a registered account. Please use a different email address, or try logging in.')
      } else if (err.code === 'auth/invalid-email') {
        setError('The email address is not valid. Please enter a valid email address.')
      } else if (err.code === 'auth/weak-password') {
        setError('The password is too weak. Please choose a stronger password.')
      } else {
        setError('An error occurred during signup. Please try again.')
      }
      setIsPending(false)
      return false  // Return false to indicate failure
    }
  }

  return { signup, error, isPending }
}