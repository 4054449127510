import { Link } from "react-router-dom";
import Carpentry from "../../components/ProfileInfo/MaterialCosts/Carpentry";
import Roofing from "../../components/ProfileInfo/MaterialCosts/Roofing";
import Electrical from "../../components/ProfileInfo/MaterialCosts/Electrical";
import ExternalFinishes from "../../components/ProfileInfo/MaterialCosts/ExternalFinishes";
import Insulation from "../../components/ProfileInfo/MaterialCosts/Insulation";
import Masonry from "../../components/ProfileInfo/MaterialCosts/Masonry";
import PaintingAndDecorating from "../../components/ProfileInfo/MaterialCosts/PaintingAndDecorating";
import PlantHire from "../../components/ProfileInfo/MaterialCosts/PlantHire";
import Drainage from "../../components/ProfileInfo/MaterialCosts/Drainage";
import Plastering from "../../components/ProfileInfo/MaterialCosts/Plastering";
import PlumbingAndHeating from "../../components/ProfileInfo/MaterialCosts/PlumbingAndHeating";
import SpecialOrder from "../../components/ProfileInfo/MaterialCosts/SpecialOrder";
import Tiling from "../../components/ProfileInfo/MaterialCosts/Tiling";
import ValidateEmail from "../../components/ProfileInfo/ValidateEmail";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useState } from "react";

export default function MaterialCosts() {
  const { user } = useAuthContext();
  const [activeTab, setActiveTab] = useState("Masonry");

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="md:px-10 px-3">
        <div className="divider divider-primary md:px-5 max-sm:px-3 xl:px-20">
          <p className="pageTitle">Material Costs</p>
        </div>
        <div className="breadcrumbs text-md sticky top-28 sm:top-28 md:top-32 lg:top-36 bg-base-100 z-40 pb-5">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Material Costs</li>
          </ul>
        </div>
        <div className="sm:hidden text-left pageSubheadingSm">
          Material Costs
        </div>
        <div className="max-sm:hidden text-left pageSubheading">
          Material Costs
        </div>

        {!user.emailVerified && (
          <>
            <div className="pageSubtitle text-left max-sm:ps-6 pb-5 ps-20">
              Please verify your email address to update material costs.
              <br />
              If you haven't received the verification email, you can resend it
              using the button below.
              <br />
            </div>
            <ValidateEmail />
          </>
        )}

        {user && user.emailVerified && (
          <>
            <div className="sm:hidden">
              <br />
            </div>
            <div className="pageSubtitle text-left max-sm:ps-6 ps-20">
              Use this page to update your material costs.
              <div className="xl:hidden">
                <br />
              </div>
              <div className="max-lg:hidden">
                <br />
                Use the tabs to select a material category, then infill the
                updated costs as required.
                <br />
                Please update the costs using the button at the bottom before
                moving between tabs.
              </div>
              <div className="text-sm md:hidden">UoM = Unit of Measurement</div>
              <br />
            </div>

            <div
              role="tablist"
              className="max-lg:hidden tabs tabs-lifted tab-border-2  card shadow-xl  mb-20">
              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab tabTitle"
                aria-label="Masonry"
                checked={activeTab === "Masonry"}
                onChange={() => handleTabChange("Masonry")}
              />
              <div
                role="tabpanel"
                className={`tab-content bg-base-100 border-base-200 tab-border-2 rounded-box p-6 justify-center ${
                  activeTab === "Masonry" ? "" : "hidden"
                }`}>
                <div className="divider divider-primary lg:px-8 xl:px-16 materialCost">
                  Masonry
                </div>
                <br />
                <Masonry />
              </div>

              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab tabTitle"
                aria-label="Carpentry"
                checked={activeTab === "Carpentry"}
                onChange={() => handleTabChange("Carpentry")}
              />
              <div
                role="tabpanel"
                className={`tab-content bg-base-100 border-base-300 rounded-box p-6 justify-center flex ${
                  activeTab === "Carpentry" ? "" : "hidden"
                }`}>
                <div className="divider divider-primary lg:px-8 xl:px-16 materialCost">
                  Carpentry
                </div>
                <br />
                <Carpentry />
              </div>

              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab tabTitle"
                aria-label="Roofing"
                checked={activeTab === "Roofing"}
                onChange={() => handleTabChange("Roofing")}
              />
              <div
                role="tabpanel"
                className={`tab-content bg-base-100 border-base-300 rounded-box p-6 ${
                  activeTab === "Roofing" ? "" : "hidden"
                }`}>
                <div className="divider divider-primary lg:px-8 xl:px-16 materialCost">
                  Roofing
                </div>
                <br />
                <Roofing />
              </div>
              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab tabTitle"
                aria-label="Insulation"
                checked={activeTab === "Insulation"}
                onChange={() => handleTabChange("Insulation")}
              />
              <div
                role="tabpanel"
                className={`tab-content bg-base-100 border-base-200 tab-border-2 rounded-box p-6 justify-center ${
                  activeTab === "Insulation" ? "" : "hidden"
                }`}>
                <div className="divider divider-primary lg:px-8 xl:px-16 materialCost">
                  Insulation
                </div>
                <br />
                <Insulation />
              </div>
              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab tabTitle"
                aria-label="Plastering"
                checked={activeTab === "Plastering"}
                onChange={() => handleTabChange("Plastering")}
              />
              <div
                role="tabpanel"
                className={`tab-content bg-base-100 border-base-300 rounded-box p-6 ${
                  activeTab === "Plastering" ? "" : "hidden"
                }`}>
                <div className="divider divider-primary lg:px-8 xl:px-16 materialCost">
                  Plastering
                </div>
                <br />
                <Plastering />
              </div>
              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab tabTitle"
                aria-label="External"
                checked={activeTab === "External"}
                onChange={() => handleTabChange("External")}
              />
              <div
                role="tabpanel"
                className={`tab-content bg-base-100 border-base-200 tab-border-2 rounded-box p-6 justify-center ${
                  activeTab === "External" ? "" : "hidden"
                }`}>
                <div className="divider divider-primary lg:px-8 xl:px-16 materialCost">
                  External Finishes
                </div>
                <br />
                <ExternalFinishes />
              </div>

              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab tabTitle"
                aria-label="Electrical"
                checked={activeTab === "Electrical"}
                onChange={() => handleTabChange("Electrical")}
              />
              <div
                role="tabpanel"
                className={`tab-content bg-base-100 border-base-300 rounded-box p-6 ${
                  activeTab === "Electrical" ? "" : "hidden"
                }`}>
                <div className="divider divider-primary lg:px-8 xl:px-16 materialCost">
                  Electrical
                </div>
                <br />
                <Electrical />
              </div>

              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab tabTitle"
                aria-label="Plumbing"
                checked={activeTab === "Plumbing"}
                onChange={() => handleTabChange("Plumbing")}
              />
              <div
                role="tabpanel"
                className={`tab-content bg-base-100 border-base-300 rounded-box p-6 ${
                  activeTab === "Plumbing" ? "" : "hidden"
                }`}>
                <div className="divider divider-primary lg:px-8 xl:px-16 materialCost">
                  Plumbing&nbsp;&amp;&nbsp;Heating
                </div>
                <br />
                <PlumbingAndHeating />
              </div>

              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab tabTitle"
                aria-label="Painting"
                checked={activeTab === "Painting"}
                onChange={() => handleTabChange("Painting")}
              />
              <div
                role="tabpanel"
                className={`tab-content bg-base-100 border-base-300 rounded-box p-6 ${
                  activeTab === "Painting" ? "" : "hidden"
                }`}>
                <div className="divider divider-primary lg:px-8 xl:px-16 materialCost">
                  Painting&nbsp;&amp;&nbsp;Decorating
                </div>
                <br />
                <PaintingAndDecorating />
              </div>

              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab tabTitle"
                aria-label="Tiling"
                checked={activeTab === "Tiling"}
                onChange={() => handleTabChange("Tiling")}
              />
              <div
                role="tabpanel"
                className={`tab-content bg-base-100 border-base-300 rounded-box p-6 ${
                  activeTab === "Tiling" ? "" : "hidden"
                }`}>
                <div className="divider divider-primary lg:px-8 xl:px-16 materialCost">
                  Wall and Floor Tiling
                </div>
                <br />
                <Tiling />
              </div>

              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab tabTitle"
                aria-label="Plant"
                checked={activeTab === "Plant"}
                onChange={() => handleTabChange("Plant")}
              />
              <div
                role="tabpanel"
                className={`tab-content bg-base-100 border-base-300 rounded-box p-6 ${
                  activeTab === "Plant" ? "" : "hidden"
                }`}>
                <div className="divider divider-primary lg:px-8 xl:px-16 materialCost">
                  Plant Hire
                </div>
                <br />
                <PlantHire />
              </div>

              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab tabTitle"
                aria-label="Drainage"
                checked={activeTab === "Drainage"}
                onChange={() => handleTabChange("Drainage")}
              />
              <div
                role="tabpanel"
                className={`tab-content bg-base-100 border-base-300 rounded-box p-6 ${
                  activeTab === "Drainage" ? "" : "hidden"
                }`}>
                <div className="divider divider-primary lg:px-8 xl:px-16 materialCost">
                  Rainwater Goods &amp; Drainage
                </div>
                <br />
                <Drainage />
              </div>

              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab tabTitle"
                aria-label="Special"
                checked={activeTab === "Special"}
                onChange={() => handleTabChange("Special")}
              />
              <div
                role="tabpanel"
                className={`tab-content bg-base-100 border-base-300 rounded-box p-6 ${
                  activeTab === "Special" ? "" : "hidden"
                }`}>
                <div className="divider divider-primary lg:px-8 xl:px-16 materialCost">
                  Special Order Materials
                </div>
                <br />
                <SpecialOrder />
              </div>
            </div>

            <div className="lg:hidden mb-20">
              {/* Accordion style for small screens */}
              <div className="collapse collapse-plus border hover:border-info border-base-300 mb-5 card shadow-xl">
                <input
                  type="radio"
                  name="my-accordion-2"
                  id="accordion-masonry"
                />
                <div className="collapse-title text-left">
                  <div className="materialCostsm">Masonry</div>
                </div>
                <div className="collapse-content">
                  {activeTab === "Masonry" && <Masonry />}
                </div>
              </div>
              <div className="collapse collapse-plus border hover:border-info border-base-300 mb-5 card shadow-xl">
                <input
                  type="radio"
                  name="my-accordion-2"
                  id="accordion-masonry"
                />
                <div className="collapse-title text-xl text-left font-medium">
                  <div className="materialCostsm">Carpentry</div>
                </div>
                <div className="collapse-content">
                  {activeTab === "Masonry" && <Carpentry />}
                </div>
              </div>
              <div className="collapse collapse-plus border hover:border-info border-base-300 mb-5 card shadow-xl">
                <input
                  type="radio"
                  name="my-accordion-2"
                  id="accordion-masonry"
                />
                <div className="collapse-title text-xl text-left font-medium">
                  <div className="materialCostsm">Roofing</div>
                </div>
                <div className="collapse-content">
                  {activeTab === "Masonry" && <Roofing />}
                </div>
              </div>
              <div className="collapse collapse-plus border hover:border-info border-base-300 mb-5 card shadow-xl">
                <input
                  type="radio"
                  name="my-accordion-2"
                  id="accordion-masonry"
                />
                <div className="collapse-title text-xl text-left font-medium">
                  <div className="materialCostsm">Insulation</div>
                </div>
                <div className="collapse-content">
                  {activeTab === "Masonry" && <Insulation />}
                </div>
              </div>
              <div className="collapse collapse-plus border hover:border-info border-base-300 mb-5 card shadow-xl">
                <input
                  type="radio"
                  name="my-accordion-2"
                  id="accordion-masonry"
                />
                <div className="collapse-title text-xl text-left font-medium">
                  <div className="materialCostsm">Plastering</div>
                </div>
                <div className="collapse-content">
                  {activeTab === "Masonry" && <Plastering />}
                </div>
              </div>
              <div className="collapse collapse-plus border hover:border-info border-base-300 mb-5 card shadow-xl">
                <input
                  type="radio"
                  name="my-accordion-2"
                  id="accordion-masonry"
                />
                <div className="collapse-title text-xl text-left font-medium">
                  <div className="materialCostsm">External Finishes</div>
                </div>
                <div className="collapse-content">
                  {activeTab === "Masonry" && <ExternalFinishes />}
                </div>
              </div>
              <div className="collapse collapse-plus border hover:border-info border-base-300 mb-5 card shadow-xl">
                <input
                  type="radio"
                  name="my-accordion-2"
                  id="accordion-masonry"
                />
                <div className="collapse-title text-xl text-left font-medium">
                  <div className="materialCostsm">Electrical</div>
                </div>
                <div className="collapse-content">
                  {activeTab === "Masonry" && <Electrical />}
                </div>
              </div>
              <div className="collapse collapse-plus border hover:border-info border-base-300 mb-5 card shadow-xl">
                <input
                  type="radio"
                  name="my-accordion-2"
                  id="accordion-masonry"
                />
                <div className="collapse-title text-xl text-left font-medium">
                  <div className="materialCostsm">Plumbing &amp; Heating</div>
                </div>
                <div className="collapse-content">
                  {activeTab === "Masonry" && <PlumbingAndHeating />}
                </div>
              </div>
              <div className="collapse collapse-plus border hover:border-info border-base-300 mb-5 card shadow-xl">
                <input
                  type="radio"
                  name="my-accordion-2"
                  id="accordion-masonry"
                />
                <div className="collapse-title text-xl text-left font-medium">
                  <div className="materialCostsm">
                    Painting &amp; Decorating
                  </div>
                </div>
                <div className="collapse-content">
                  {activeTab === "Masonry" && <PaintingAndDecorating />}
                </div>
              </div>
              <div className="collapse collapse-plus border hover:border-info border-base-300 mb-5 card shadow-xl">
                <input
                  type="radio"
                  name="my-accordion-2"
                  id="accordion-masonry"
                />
                <div className="collapse-title text-xl text-left font-medium">
                  <div className="materialCostsm">Tiling</div>
                </div>
                <div className="collapse-content">
                  {activeTab === "Masonry" && <Tiling />}
                </div>
              </div>
              <div className="collapse collapse-plus border hover:border-info border-base-300 mb-5 card shadow-xl">
                <input
                  type="radio"
                  name="my-accordion-2"
                  id="accordion-masonry"
                />
                <div className="collapse-title text-xl text-left font-medium">
                  <div className="materialCostsm">Plant Hire</div>
                </div>
                <div className="collapse-content">
                  {activeTab === "Masonry" && <PlantHire />}
                </div>
              </div>
              <div className="collapse collapse-plus border hover:border-info border-base-300 mb-5 card shadow-xl">
                <input
                  type="radio"
                  name="my-accordion-2"
                  id="accordion-masonry"
                />
                <div className="collapse-title text-xl text-left font-medium">
                  <div className="materialCostsm">
                    Rainwater Goods &amp; Drainage
                  </div>
                </div>
                <div className="collapse-content">
                  {activeTab === "Masonry" && <Drainage />}
                </div>
              </div>
              <div className="collapse collapse-plus border hover:border-info border-base-300 mb-5 card shadow-xl">
                <input
                  type="radio"
                  name="my-accordion-2"
                  id="accordion-masonry"
                />
                <div className="collapse-title text-xl text-left font-medium">
                  <div className="materialCostsm">Special Order Materials</div>
                </div>
                <div className="collapse-content">
                  {activeTab === "Masonry" && <SpecialOrder />}
                </div>
              </div>

              {/* Add similar collapse components for other material costs */}
            </div>
          </>
        )}
      </div>
    </>
  );
}
