import { useState, useCallback } from 'react';
import { getAuth, sendEmailVerification } from 'firebase/auth';

export const useValidate = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);

  const sendEmailValidation = useCallback(async () => {
    console.log('sendEmailValidation function called');
    setError(null);
    setIsPending(true);

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      console.log('Current user:', user);

      if (!user) {
        throw new Error('No user is currently signed in');
      }

      await sendEmailVerification(user);
      console.log('Email verification sent successfully');
      setIsPending(false);
    } catch (err) {
      console.error('Error sending email verification:', err);
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
      setIsPending(false);
    }
  }, []);

  return { sendEmailValidation, isPending, error };
};