// ForgotPassword.js
import React, { useState, useEffect } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { At, PaperPlaneTilt, X } from '@phosphor-icons/react';

const ForgotPassword = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        let timer;
        if (isSubmitted) {
            timer = setTimeout(() => {
                onClose();
            }, 5000);
        }
        return () => clearTimeout(timer);
    }, [isSubmitted, onClose]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const auth = getAuth();
        try {
            await sendPasswordResetEmail(auth, email);
            setMessage("We've sent you an email with instructions on how to reset your password. Please check your inbox.");
            setIsSubmitted(true);
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }
    };

    return (
        <div className="modal modal-open">
            <div className="modal-box">
                <h3 className="font-bold text-lg text-left">If you've forgotten your password, enter your email address here and we'll send you a password reset link.</h3>
                <form onSubmit={handleSubmit}>
                    <label className="input input-bordered flex items-center gap-2 mt-4">
                        <At size={25}/>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="w-full"
                        />
                    </label>
                    <div className="modal-action">
                        <button type="submit" className="btn btn-primary"><PaperPlaneTilt size={25}/>Send Reset Email</button>
                        <button type="button" onClick={onClose} className="btn"><X size={25}/>Close</button>
                    </div>
                </form>
                {message && <p className="mt-4">{message}</p>}
            </div>
        </div>
    );
};

export default ForgotPassword;