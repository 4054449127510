import React from 'react'
import { useAuthContext } from '../../hooks/useAuthContext'


export default function CompanyName() {
  const { user } = useAuthContext()
  return (
    <>{user && user.displayName}</>
  )
}
