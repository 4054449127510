import { Link } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";

// components
import ValidateEmail from "../../components/ProfileInfo/ValidateEmail";
import LabourRatesForm from "../../components/ProfileInfo/LabourRates/LabourRatesForm";

export default function LabourRates() {
  const { user } = useAuthContext();

  return (
    <>
      <div className=" md:px-10 px-3">
        <div className="divider divider-primary md:px-5 max-sm:px-3 xl:px-20">
          <p className="sm:hidden pageTitleSm">Labour Rates</p>
          <p className="max-sm:hidden pageTitle">Labour Rates</p>
        </div>
        <div className="breadcrumbs text-md sticky top-28 sm:top-28 md:top-32 lg:top-36 bg-base-100 z-40 pb-5">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Labour Rates</li>
          </ul>
        </div>
        <div className="sm:hidden text-left pageSubheadingSm">Labour Rates</div>
        <div className="max-sm:hidden text-left pageSubheading">
          Labour Rates
        </div>

        {!user.emailVerified && (
          <>
            <div className="pageSubtitle text-left max-sm:ps-6 pb-5 ps-20">
              Please verify your email address to update labour rates.
              <br />
              If you haven't received the verification email, you can resend it
              using the button below.
              <br />
            </div>
            <ValidateEmail />
          </>
        )}
        {user.emailVerified && (
          <>
            <div className="pageSubtitle text-left max-sm:ps-6 ps-20">
              Use this page to update your Labour rates for tradespersons on
              site. <br />
              Rates are based on an 8-hour working day.
              <br />
              <br />
            </div>
            <LabourRatesForm />
            <br />
            <br />
            <br />
            <br />
          </>
        )}
      </div>
    </>
  );
}
