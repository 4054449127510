import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAuth,
  applyActionCode,
  verifyPasswordResetCode,
  confirmPasswordReset,
  multiFactor,
} from "firebase/auth"; // Added multiFactor import
import {
  CheckCircle,
  XCircle,
  EyeSlash,
  Eye,
  Password,
  ShieldCheck,
} from "@phosphor-icons/react"; // Added ShieldCheck import
import DisabledButton from "../../components/Buttons/DisabledButton";
import CreateButton from "../../components/Buttons/CreateButton";

const AuthActions = () => {
  const [state, setState] = useState({
    mode: null,
    actionCode: null,
    email: "",
    newPassword: "",
    confirmPassword: "",
    message: "",
    error: "",
    loading: false,
    showPassword: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialChar: false,
    isLongEnough: false,
  });

  const [countdown, setCountdown] = useState(3);
  const [devMode, setDevMode] = useState(
    process.env.NODE_ENV === "development"
  );

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!devMode) {
      const searchParams = new URLSearchParams(location.search);
      const mode = searchParams.get("mode");
      const actionCode = searchParams.get("oobCode");

      console.log("AuthActions: Initial parameters", { mode, actionCode });

      setState((prevState) => ({ ...prevState, mode, actionCode }));

      if (mode && actionCode) {
        handleAction(mode, actionCode);
      } else {
        console.log("AuthActions: Invalid or missing parameters");
        setState((prevState) => ({
          ...prevState,
          error: "Invalid or missing parameters",
          loading: false,
        }));
      }
    }
  }, [location, devMode]);

  useEffect(() => {
    if (state.newPassword) {
      console.log("AuthActions: Checking password strength");
      setState((prevState) => ({
        ...prevState,
        hasUpperCase: /[A-Z]/.test(state.newPassword),
        hasLowerCase: /[a-z]/.test(state.newPassword),
        hasNumber: /[0-9]/.test(state.newPassword),
        hasSpecialChar: /[!@#$%^&£~*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(
          state.newPassword
        ),
        isLongEnough: state.newPassword.length >= 8,
      }));
    }
  }, [state.newPassword]);

  useEffect(() => {
    let timer;
    if (state.message && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);
    } else if (countdown === 0) {
      window.location.href = "/MyProfile"; // This will cause a hard reload
    }
    return () => clearTimeout(timer);
  }, [state.message, countdown]);

  const handleAction = async (mode, actionCode) => {
    console.log("AuthActions: Handling action", { mode, actionCode });
    const auth = getAuth();

    try {
      switch (mode) {
        case "verifyEmail":
          console.log("AuthActions: Verifying email");
          await applyActionCode(auth, actionCode);
          setState((prevState) => ({
            ...prevState,
            message:
              "Your email address has been verified successfully. You can now sign in.",
            loading: false,
          }));
          break;
        case "resetPassword":
          console.log("AuthActions: Verifying password reset code");
          const email = await verifyPasswordResetCode(auth, actionCode);
          setState((prevState) => ({
            ...prevState,
            email,
            loading: false,
          }));
          break;
        // New case for revertSecondFactorAddition
        case "revertSecondFactorAddition":
          console.log("AuthActions: Reverting second factor addition");
          await multiFactor(auth.currentUser).unenroll(actionCode);
          setState((prevState) => ({
            ...prevState,
            message:
              "Multi-Factor Authentication has been removed successfully.",
            loading: false,
          }));
          break;
        default:
          console.log("AuthActions: Unsupported action mode", mode);
          setState((prevState) => ({
            ...prevState,
            error: "Unsupported action mode",
            loading: false,
          }));
      }
    } catch (error) {
      console.error("AuthActions: Error handling action", error);
      setState((prevState) => ({
        ...prevState,
        error: `Error: ${error.message}`,
        loading: false,
      }));
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    console.log("AuthActions: Attempting to reset password");

    if (!isPasswordValid()) {
      console.log("AuthActions: Password does not meet requirements");
      setState((prevState) => ({
        ...prevState,
        error: "Password does not meet all requirements",
      }));
      return;
    }

    const auth = getAuth();
    setState((prevState) => ({ ...prevState, loading: true }));

    try {
      console.log("AuthActions: Confirming password reset");
      await confirmPasswordReset(auth, state.actionCode, state.newPassword);
      setState((prevState) => ({
        ...prevState,
        message: "Your password has been reset successfully!",
        loading: false,
      }));
      console.log("AuthActions: Password reset successful");
      setCountdown(3);
    } catch (error) {
      console.error("AuthActions: Error resetting password", error);
      setState((prevState) => ({
        ...prevState,
        error: `Error: ${error.message}`,
        loading: false,
      }));
    }
  };

  const isPasswordValid = () => {
    return (
      state.hasUpperCase &&
      state.hasLowerCase &&
      state.hasNumber &&
      state.hasSpecialChar &&
      state.isLongEnough &&
      state.newPassword === state.confirmPassword
    );
  };

  const togglePasswordVisibility = () => {
    console.log("AuthActions: Toggling password visibility");
    setState((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  const renderPasswordRequirements = () => (
    <div className="text-sm mt-4">
      <div className="divider divider-primary passwordRules">
        Your password must contain:
      </div>
      <ul className="space-y-1">
        {[
          {
            condition: state.hasUpperCase,
            text: "At least one uppercase letter.",
          },
          {
            condition: state.hasLowerCase,
            text: "At least one lowercase letter.",
          },
          { condition: state.hasNumber, text: "At least one number." },
          {
            condition: state.hasSpecialChar,
            text: "At least one special character.",
          },
          {
            condition: state.isLongEnough,
            text: "At least eight characters in total.",
          },
        ].map((requirement, index) => (
          <li key={index} className="flex items-center">
            <span className="mr-2 inline-flex items-center justify-center">
              {requirement.condition ? (
                <CheckCircle
                  size={25}
                  weight="duotone"
                  className="text-success"
                />
              ) : (
                <XCircle size={25} weight="duotone" />
              )}
            </span>
            <span>{requirement.text}</span>
          </li>
        ))}
      </ul>
    </div>
  );

  const renderContent = () => {
    if (state.loading) {
      return (
        <div className="text-center">
          <span className="loading loading-ring loading-lg text-info"></span>
          <p>Loading, please wait...</p>
        </div>
      );
    }

    if (state.error) {
      return (
        <div className="alert alert-error flex justify-center items-center">
          <XCircle size={24} />
          <span>{state.error}</span>
        </div>
      );
    }

    if (state.message) {
      return (
        <div className="flex flex-col items-center">
          <div className="alert alert-info mb-4 text-center flex justify-center items-center">
            <CheckCircle size={30} />
            <span>{state.message}</span>
          </div>
          <div className="relative w-20 h-20">
            <svg className="w-full h-full" viewBox="0 0 100 100">
              <circle
                className="text-primary opacity-20"
                strokeWidth="10"
                stroke="currentColor"
                fill="transparent"
                r="45"
                cx="50"
                cy="50"
                strokeLinecap="round"
              />
              <circle
                className="text-primary animate-progress"
                strokeWidth="10"
                strokeDasharray={283}
                strokeDashoffset={283}
                stroke="currentColor"
                fill="transparent"
                r="45"
                cx="50"
                cy="50"
                strokeLinecap="round"
                transform="rotate(-90 50 50)"
              />
            </svg>
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <span className="text-3xl font-bold countdown-number">
                {countdown}
              </span>
            </div>
          </div>
          <p className="mt-4">Redirecting you...</p>
        </div>
      );
    }

    if (state.mode === "resetPassword") {
      return (
        <form onSubmit={handleResetPassword}>
          <div className="form-control mb-4">
            <p className="text-lg font-semibold">Change the password for:</p>
            <p className="text-primary">{state.email}</p>
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">New Password</span>
            </label>
            <label className="input input-bordered flex items-center gap-2 pe-0">
              <Password size={20} />
              <input
                type={state.showPassword ? "text" : "password"}
                placeholder="Enter new password"
                className="grow"
                value={state.newPassword}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    newPassword: e.target.value,
                  }))
                }
                required
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="btn btn-link text-base-content"
              >
                {state.showPassword ? (
                  <EyeSlash size={25} />
                ) : (
                  <Eye size={25} />
                )}
              </button>
            </label>
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Confirm Password</span>
            </label>
            <label className="input input-bordered flex items-center gap-2 pe-0">
              <Password size={20} />
              <input
                type={state.showPassword ? "text" : "password"}
                placeholder="Confirm new password"
                className="grow"
                value={state.confirmPassword}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    confirmPassword: e.target.value,
                  }))
                }
                required
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="btn btn-link text-base-content"
              >
                {state.showPassword ? (
                  <EyeSlash size={25} />
                ) : (
                  <Eye size={25} />
                )}
              </button>
            </label>
          </div>

          {renderPasswordRequirements()}

          <div className="form-control mt-6">
            {isPasswordValid() ? <CreateButton /> : <DisabledButton />}
          </div>
        </form>
      );
    }

    // New content for revertSecondFactorAddition
    if (state.mode === "revertSecondFactorAddition") {
      return (
        <div className="text-center">
          <ShieldCheck size={64} className="mx-auto mb-4 text-primary" />
          <h2 className="text-2xl font-bold mb-4">
            Remove Multi-Factor Authentication
          </h2>
          <p className="mb-4">
            Are you sure you want to remove Multi-Factor Authentication from
            your account?
          </p>
          <button
            onClick={() =>
              handleAction("revertSecondFactorAddition", state.actionCode)
            }
            className="btn btn-primary"
          >
            Confirm Removal
          </button>
        </div>
      );
    }

    return null;
  };

  const renderDevControls = () => {
    if (!devMode) return null;

    return (
      <div className="mb-4 p-4 border border-gray-300 rounded">
        <h3 className="font-bold mb-2">Development Controls</h3>
        <select
          value={state.mode}
          onChange={(e) =>
            setState((prev) => ({ ...prev, mode: e.target.value }))
          }
          className="select select-bordered w-full max-w-xs mb-2"
        >
          <option value="verifyEmail">Verify Email</option>
          <option value="resetPassword">Reset Password</option>
          <option value="revertSecondFactorAddition">Remove MFA</option>{" "}
          {/* New option */}
        </select>
        <input
          type="text"
          value={state.email}
          onChange={(e) =>
            setState((prev) => ({ ...prev, email: e.target.value }))
          }
          placeholder="Email"
          className="input input-bordered w-full max-w-xs mb-2"
        />
        <div className="flex gap-2">
          <button
            onClick={() =>
              setState((prev) => ({
                ...prev,
                message: "Action completed successfully!",
                loading: false,
              }))
            }
            className="btn btn-primary"
          >
            Simulate Success
          </button>
          <button
            onClick={() =>
              setState((prev) => ({
                ...prev,
                loading: true,
                message: "",
                error: "",
              }))
            }
            className="btn btn-secondary"
          >
            Simulate Loading
          </button>
          <button
            onClick={() =>
              setState((prev) => ({
                ...prev,
                error: "An error occurred.",
                loading: false,
                message: "",
              }))
            }
            className="btn btn-accent"
          >
            Simulate Error
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-base-100 md:px-10 px-4 my-0">
      {renderDevControls()}
      <div className="divider divider-primary mb-0">
        <p className="pageTitle">
          {state.mode === "verifyEmail"
            ? "Email Verification"
            : state.mode === "resetPassword"
            ? "Reset Password"
            : state.mode === "revertSecondFactorAddition"
            ? "Remove MFA" // New condition
            : "Authentication Action"}
        </p>
      </div>
      <div className="hero-content flex-col mx-auto pt-20">
        <div className="card flex-shrink-0 w-full max-sm:max-w-sm max-w-xl shadow-2xl bordered border-4 bg-base-100">
          <div className="card-body">{renderContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default AuthActions;
