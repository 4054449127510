import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  CheckCircle,
  MagnifyingGlass,
  XCircle,
  Clock,
  CaretDoubleLeft,
  CaretLeft,
  CaretDoubleRight,
  CaretRight,
} from "@phosphor-icons/react";
import PoundIcon from "../../../assets/icons/FormFields/PoundIcon";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  query,
  orderBy,
  serverTimestamp,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { apersuDatabase } from "../../../firebase/config";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatDistanceToNow, format, differenceInMonths } from "date-fns";

export default function Drainage() {
  const { user } = useAuthContext();
  const [search, setSearch] = useState("");
  const [updatedCosts, setUpdatedCosts] = useState({});
  const [errors, setErrors] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCost, setSelectedCost] = useState(null);
  const [drainage, setDrainage] = useState([]);
  const newItemsToastShownRef = useRef(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(drainage.length / itemsPerPage);

  const renderPageNumbers = () => {
    let pages = [];
    let start, end;

    if (currentPage === 1) {
      start = 1;
      end = Math.min(3, totalPages);
    } else if (currentPage === totalPages) {
      start = Math.max(1, totalPages - 2);
      end = totalPages;
    } else {
      start = currentPage - 1;
      end = currentPage + 1;
    }

    for (let i = start; i <= end; i++) {
      pages.push(
        <button
          key={i}
          className={`btn btn-ghost w-12 ${
            currentPage === i ? "btn-active" : ""
          }`}
          onClick={() => paginate(i)}>
          {i}
        </button>
      );
    }

    return pages;
  };

  const getDate = (dateOrTimestamp) => {
    if (dateOrTimestamp instanceof Date) {
      return dateOrTimestamp;
    }
    if (dateOrTimestamp && typeof dateOrTimestamp.toDate === "function") {
      return dateOrTimestamp.toDate();
    }
    if (typeof dateOrTimestamp === "string") {
      return new Date(dateOrTimestamp);
    }
    return null;
  };

  const fetchLatestData = useCallback(async () => {
    try {
      // Fetch default drainage data
      const defaultDrainageRef = collection(
        apersuDatabase,
        "users/default/info/materialCosts/drainage"
      );
      const defaultSnapshot = await getDocs(defaultDrainageRef);
      let defaultDocs = defaultSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        isDefault: true,
      }));

      // Fetch user's drainage data
      const userDrainageRef = collection(
        apersuDatabase,
        `users/${user.uid}/info/materialCosts/drainage`
      );
      const userSnapshot = await getDocs(userDrainageRef);
      let userDocs = userSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        isDefault: false,
      }));

      // Find items in default that are not in user's collection
      const itemsToAdd = defaultDocs.filter(
        (defaultDoc) =>
          !userDocs.some((userDoc) => userDoc.id === defaultDoc.id)
      );

      // Add missing items to user's collection
      for (const item of itemsToAdd) {
        const newDocRef = doc(userDrainageRef, item.id);
        await setDoc(newDocRef, {
          productName: item.productName,
          UoM: item.UoM,
          productCost: item.productCost,
          costHistory: [],
          dateLastChanged: serverTimestamp(),
        });
      }

      // If we added any items, fetch the user's data again
      if (itemsToAdd.length > 0) {
        const updatedUserSnapshot = await getDocs(userDrainageRef);
        userDocs = updatedUserSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          isDefault: false,
        }));
      }

      // Merge default and user docs, prioritizing user docs
      let mergedDocs = [...defaultDocs];
      userDocs.forEach((userDoc) => {
        const index = mergedDocs.findIndex((doc) => doc.id === userDoc.id);
        if (index !== -1) {
          mergedDocs[index] = userDoc;
        } else {
          mergedDocs.push(userDoc);
        }
      });

      // Sort by productName
      mergedDocs.sort((a, b) => a.productName.localeCompare(b.productName));

      setDrainage(mergedDocs);

      if (itemsToAdd.length > 0 && !newItemsToastShownRef.current) {
        toast.info(
          `${itemsToAdd.length} new item(s) added to your drainage collection.`,
          {
            autoClose: 3000,
          }
        );
        newItemsToastShownRef.current = true;
      }
    } catch (error) {
      console.error("Error fetching latest data:", error);
      toast.error("Error fetching latest data: " + error.message, {
        autoClose: 3000,
      });
    }
  }, [user.uid]);

  useEffect(() => {
    if (user) {
      fetchLatestData();
    }
  }, [user, fetchLatestData]);

  const handleCostChange = (id, newCost) => {
    setUpdatedCosts((prev) => ({ ...prev, [id]: newCost }));
    setErrors((prev) => ({ ...prev, [id]: null }));
  };

  const updateCosts = async (e) => {
    e.preventDefault();

    try {
      const userDrainageRef = collection(
        apersuDatabase,
        `users/${user.uid}/info/materialCosts/drainage`
      );
      const clientNow = new Date();

      for (const [id, newCost] of Object.entries(updatedCosts)) {
        const costDocRef = doc(userDrainageRef, id);
        const docSnap = await getDoc(costDocRef);

        const newCostEntry = {
          value: Number(parseFloat(newCost).toFixed(2)),
          timestamp: clientNow.toISOString(),
        };

        let costHistory = docSnap.exists()
          ? docSnap.data().costHistory || []
          : [];
        costHistory.unshift(newCostEntry);
        costHistory = costHistory.slice(0, 5);

        const updateData = {
          productCost: Number(parseFloat(newCost).toFixed(2)),
          costHistory: costHistory,
          dateLastChanged: serverTimestamp(),
        };

        if (!docSnap.exists()) {
          // If the document doesn't exist in the user's collection, add the default fields
          const defaultDoc = drainage.find(
            (item) => item.id === id && item.isDefault
          );
          if (defaultDoc) {
            updateData.productName = defaultDoc.productName;
            updateData.UoM = defaultDoc.UoM;
          }
        }

        await setDoc(costDocRef, updateData, { merge: true });
      }

      setUpdatedCosts({});
      toast.success("Costs updated successfully!");

      // Add a delay of 500 milliseconds before fetching the latest data
      setTimeout(() => {
        fetchLatestData(); // Refresh the data after update
      }, 100);
    } catch (error) {
      console.error("Error updating costs:", error);
      toast.error("Error updating costs: " + error.message);
    }
  };

  const cancelUpdates = () => {
    setUpdatedCosts({});
  };

  const openModal = (cost) => {
    setSelectedCost(cost);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedCost(null);
  };

  const restoreCost = async (cost) => {
    try {
      const userDrainageRef = collection(
        apersuDatabase,
        `users/${user.uid}/info/materialCosts/drainage`
      );
      const costDocRef = doc(userDrainageRef, selectedCost.id);

      const clientNow = new Date();

      await updateDoc(costDocRef, {
        productCost: cost.value,
        dateLastChanged: serverTimestamp(),
      });

      closeModal();
      toast.success("Cost restored successfully!");
      fetchLatestData(); // Refresh the data after restore
    } catch (error) {
      toast.error("Error restoring cost: " + error.message);
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = drainage
    .filter((cost) =>
      search.toLowerCase() === ""
        ? cost
        : cost.productName.toLowerCase().includes(search)
    )
    .slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div>
      <div className="xl:ps-20 lg:ps-10">
        <label className="input input-secondary input-bordered flex max-w-md max-sm:w-full items-center gap-2 ">
          <MagnifyingGlass />
          <input
            type="search"
            className="grow "
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
        </label>
      </div>
      <div className="overflow-x-auto">
        <div className="flex flex-col">
          {/* Header */}
          <div className="flex flex-row border-b-2 border-base-300 py-2 tableHeader">
            <div className="w-1/2 text-left ps-5">Product</div>
            <div className="w-1/6 max-md:hidden">Unit Of Measurement</div>
            <div className="w-1/6 md:hidden">UoM</div>
            <div className="w-1/6 max-md:hidden">Current Cost</div>
            <div className="w-1/6 md:hidden">Cost</div>
            <div className="w-1/6">New Cost</div>
            <div className="w-1/6 max-lg:hidden">Last changed</div>
            <div className="w-1/6 max-lg:hidden">History</div>
          </div>

          {/* Body */}
          {currentItems.map((cost) => (
            <div
              key={cost.id}
              className="flex flex-row items-center hover:bg-base-200 py-2 border-b border-base-300">
              <div className="w-1/2 text-left ps-5">{cost.productName}</div>
              <div className="w-1/6 max-md:hidden">{cost.UoM}</div>
              <div className="w-1/6 md:hidden">{cost.UoM}</div>
              <div className="w-1/6 items-center">
                £
                {(typeof cost.productCost === "number"
                  ? cost.productCost
                  : parseFloat(cost.productCost)
                ).toFixed(2)}
              </div>
              <div className="w-1/6">
                <label className="input input-bordered flex items-center max-sm:w-20 w-32 gap-1 px-1">
                  <PoundIcon />
                  <input
                    type="number"
                    maxLength="4"
                    className="w-20"
                    value={updatedCosts[cost.id] || ""}
                    onChange={(e) => handleCostChange(cost.id, e.target.value)}
                    placeholder={(typeof cost.productCost === "number"
                      ? cost.productCost
                      : parseFloat(cost.productCost)
                    ).toFixed(2)}
                  />
                </label>
              </div>
              <div className="w-1/6 max-lg:hidden">
                {cost.dateLastChanged &&
                !cost.isDefault &&
                cost.costHistory &&
                cost.costHistory.length > 0 ? (
                  <>
                    {formatDistanceToNow(getDate(cost.dateLastChanged), {
                      addSuffix: true,
                    })}
                    {differenceInMonths(
                      new Date(),
                      getDate(cost.dateLastChanged)
                    ) >= 6 && (
                      <div
                        className="tooltip"
                        data-tip="This cost has not been updated in over 6 months. Please make sure you're satisfied with the cost shown.">
                        <span className="badge badge-info badge-xs md:badge-sm ml-2"></span>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      className="tooltip"
                      data-tip="This cost has never been updated. PLease check that you're satisfied with the cost shown.">
                      <span className="badge badge-warning badge-xs md:badge-sm ml-2"></span>
                    </div>
                    &nbsp;Never
                  </>
                )}
              </div>
              <div className="w-1/6 max-lg:hidden">
                <button
                  className="btn btn-sm btn-ghost"
                  onClick={() => openModal(cost)}
                  disabled={!cost.costHistory || cost.costHistory.length === 0}>
                  <Clock size={20} />
                  History
                </button>
              </div>
            </div>
          ))}
        </div>
        {/* Pagination */}
        <div className="flex justify-center items-center mt-4 mb-3">
          <div className="bg-base-200 rounded-full px-4 py-2 flex items-center">
            <button
              className="btn btn-ghost w-12"
              onClick={() => paginate(1)}
              // disabled={currentPage === 1}
            >
              <CaretDoubleLeft size={22} />
            </button>
            <button
              className="btn btn-ghost w-12"
              onClick={() => paginate(Math.max(1, currentPage - 1))}
              // disabled={currentPage === 1}
            >
              <CaretLeft size={22} />
            </button>
            <div className="join px-4">{renderPageNumbers()}</div>
            <button
              className="btn btn-ghost w-12"
              onClick={() => paginate(Math.min(totalPages, currentPage + 1))}
              // disabled={currentPage === totalPages}
            >
              <CaretRight size={22} />
            </button>
            <button
              className="btn btn-ghost w-12"
              onClick={() => paginate(totalPages)}
              // disabled={currentPage === totalPages}
            >
              <CaretDoubleRight size={22} />
            </button>
          </div>
        </div>
        {/*  */}
        <button className="btn md:btn-wide btn-secondary" onClick={updateCosts}>
          <CheckCircle size={25} /> Update Costs
        </button>
        &nbsp;&nbsp;
        <button className="btn md:btn-wide btn-primary" onClick={cancelUpdates}>
          <XCircle size={25} /> Cancel
        </button>
      </div>
      {modalOpen && selectedCost && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="p-6 rounded-lg bg-base-100 relative z-50">
            <h2 className="text-xl font-bold mb-4">
              Cost History for {selectedCost.productName} ({selectedCost.UoM})
            </h2>
            <table className="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Cost</th>
                  <th>Restore</th>
                </tr>
              </thead>
              <tbody>
                {selectedCost.costHistory &&
                  selectedCost.costHistory.map((cost, index) => (
                    <tr key={index}>
                      <td>{format(getDate(cost.timestamp), "dd/MM/yy")}</td>
                      <td>£{cost.value.toFixed(2)}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => restoreCost(cost)}>
                          Restore
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <button className="btn btn-sm btn-ghost mt-4" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
}
