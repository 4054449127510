import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLogout } from '../../hooks/useLogout';
import { useAuthContext } from '../../hooks/useAuthContext';
import { SignOut, Warning, XCircle } from '@phosphor-icons/react';
import CompanyName from "../ProfileInfo/CompanyName";

export default function Navbar() {
  const { logout } = useLogout();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const mobileMenuRef = useRef(null);

  const handleLogout = () => {
    logout();
    document.getElementById('my_modal_3').close();
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleNavigation = (path) => {
    closeMobileMenu();
    navigate(path);
  };

  // Close mobile menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        closeMobileMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {!user && (
        // Logged Out Navbar
        <div className="sticky top-0 z-50 bg-base-100 sm:pb-1 pb-2 lg:px-10 xl:px-28 flex flex-col
                h-10 sm:h-10 md:h-32 pt-2 shadow-xl">
          <Link to="/">
            <span className="ae-logo">æ</span>&nbsp;&nbsp;
            <span className="apersu-logo">APERSU</span>
            <span className="apersu-logo"><b>ESTIMATING</b></span>
          </Link>
        </div>
      )}
      {user && (
        // Logged In Navbar
        <div className="sticky top-0 z-50 bg-base-100 sm:pb-1 pb-2 lg:px-10 xl:px-28 flex flex-col
                h-28 sm:h-28 md:h-32 lg:h-36 pt-2">
          <div className="navbar w-full flex justify-between items-center">
            <div className="navbar-start">
              <div className="dropdown" ref={mobileMenuRef}>
                <div
                  tabIndex={0}
                  role="button"
                  className="btn btn-ghost lg:hidden"
                  onClick={toggleMobileMenu}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
                  </svg>
                </div>
                <ul className={`menu menu-lg dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-1  shadow-xl border-primary bordered border-2 ${isMobileMenuOpen ? '' : 'hidden'}`}>
                  <li><Link to="/MyProfile" onClick={() => handleNavigation('/MyProfile')}>My Profile</Link></li>
                  <li><Link to="/MyEstimates" onClick={() => handleNavigation('/MyEstimates')}>My Estimates</Link></li>
                  <li><Link to="/LabourRates" onClick={() => handleNavigation('/LabourRates')}>Labour Rates</Link></li>
                  <li><Link to="/MaterialCosts" onClick={() => handleNavigation('/MaterialCosts')}>Material Costs</Link></li>
                </ul>
              </div>
              <Link to="/" onClick={closeMobileMenu}>
                <span className="ae-logo">æ</span>&nbsp;&nbsp;
                <span className="apersu-logo">APERSU</span>
                <span className="apersu-logo"><b>ESTIMATING</b></span>
              </Link>
            </div>
            <div className="navbar-center hidden lg:flex">
              <ul className="menu menu-horizontal px-0 xl:text-lg lg:text-lg">
                <li><Link to="/MyProfile">Profile</Link></li>
                <li><Link to="/MyEstimates">Estimates</Link></li>
                <li><Link to="/LabourRates">Labour Rates</Link></li>
                <li><Link to="/MaterialCosts">Material Costs</Link></li>
              </ul>
            </div>
            <div className="navbar-end">
              <button className="btn btn-sm max-sm:hidden lg:btn-md btn-primary" onClick={() => document.getElementById('my_modal_3').showModal()}>
                <SignOut size={25}/>Log Out
              </button>
              <button className="btn btn-sm sm:hidden btn-primary" onClick={() => document.getElementById('my_modal_3').showModal()}>
                Log Out
              </button>
            </div>
          </div>
          <div className="w-full text-center">
            <span className="companyTitle"><CompanyName/></span>
          </div>
        </div>
      )}

      {/* Logout Modal */}
      <dialog id="my_modal_3" className="modal">
        <div className="modal-box  shadow-xl border-primary bordered border-4">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"><XCircle size={30}/></button>
          </form>
          <h3 className="font-bold pageSubtitle text-lg">Confirm Log Out</h3>
          <p className="py-4">Are you sure you want to log out?<br/>You will be returned to the log in screen.</p>
          <button className="btn lg:btn-md btn-primary" onClick={handleLogout}><Warning size={35}/>&nbsp;Log Out</button>
          <br/><br/>
          Something not right? <a className="blueLink" target="_blank" rel="noreferrer" 
          href="https://docs.google.com/document/d/1OXwHPv1UM7e6ZskE835wGxlKCtvlS5SzP19RLNb9Bew/edit">
            Report it here.</a>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
}