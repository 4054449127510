import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import { updateProfile, getAuth, multiFactor } from "firebase/auth";
import { apersuDatabase, apersuAuthentication } from "../../firebase/config";
import debounce from "lodash/debounce";
import { useAuthContext } from "../../hooks/useAuthContext";
import "./number.css";
import {
  At,
  BuildingOffice,
  Headset,
  IdentificationBadge,
  Percent,
  Phone,
  Info,
  SealCheck,
  ShieldWarning,
  CheckCircle,
  XCircle,
} from "@phosphor-icons/react";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MFAManager from "../MFAManager";

export default function CompanyInfoForm() {
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [filledFields, setFilledFields] = useState({});
  const [showMFAModal, setShowMFAModal] = useState(false);
  const [isMFAEnabled, setIsMFAEnabled] = useState(false);

  const auth = getAuth();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const isLoadingRef = useRef(false);

  const capitalizeWords = (value) =>
    value.replace(/\b\w/g, (char) => char.toUpperCase());

  const updateFormValues = useCallback(
    (profileData) => {
      Object.entries(profileData).forEach(([key, value]) =>
        setValue(key, value)
      );
    },
    [setValue]
  );

  const debouncedUpdate = useCallback(
    debounce(async (data) => {
      if (!user?.uid) return;
      try {
        const updatedRef = doc(
          apersuDatabase,
          `users/${user.uid}/info/profile`
        );
        await updateDoc(updatedRef, {
          ...data,
          _updatedAt: serverTimestamp(),
        });
      } catch (error) {
        console.error("Error updating profile:", error);
        throw error;
      }
    }, 500),
    [user?.uid]
  );

  useEffect(() => {
    if (!user) return;

    const docRef = doc(apersuDatabase, `users/${user.uid}/info/profile`);

    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const profileData = { ...docSnap.data(), email: user.email };
          updateFormValues(profileData);
          localStorage.setItem(
            `profile_${user.uid}`,
            JSON.stringify(profileData)
          );
        } else {
          const initialProfile = {
            uid: user.uid,
            email: user.email,
            companyName: user.displayName || "",
          };
          setDoc(docRef, initialProfile);
          updateFormValues(initialProfile);
        }
        isLoadingRef.current = false;
      },
      (error) => {
        console.error("Error fetching profile:", error);
        toast.error("Error fetching profile: " + error.message);
        isLoadingRef.current = false;
      }
    );

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user, updateFormValues]);

  const onSubmit = async (data) => {
    if (isLoadingRef.current) return;
    isLoadingRef.current = true;
    setIsLoading(true);

    try {
      const updatedData = { ...data, email: user.email };
      await debouncedUpdate(updatedData);

      if (user && data.companyName && data.companyName !== user.displayName) {
        await updateProfile(auth.currentUser, {
          displayName: data.companyName,
        });
        await auth.currentUser.reload();
      }
      toast.success("Profile updated successfully!", {
        autoClose: 2000,
        containerId: "CompanyInfoForm",
      });
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Error updating profile: " + error.message, {
        autoClose: 2000,
        containerId: "CompanyInfoForm",
      });
    } finally {
      isLoadingRef.current = false;
      setIsLoading(false);
    }
  };

  const watchAllFields = watch();
  const requiredFields = useMemo(
    () => [
      "companyName",
      "primaryContactName",
      "primaryContactPhone",
      "addressLine1",
      "postCode",
      "contingencyMargin",
      "profitMargin",
    ],
    []
  );

  useEffect(() => {
    const newFilledFields = requiredFields.reduce((acc, field) => {
      acc[field] = !!watchAllFields[field];
      return acc;
    }, {});

    setFilledFields((prevFilledFields) => {
      if (
        JSON.stringify(prevFilledFields) !== JSON.stringify(newFilledFields)
      ) {
        return newFilledFields;
      }
      return prevFilledFields;
    });
  }, [watchAllFields, requiredFields]);
  // ... (keep other existing functions like capitalizeWords, updateFormValues, debouncedUpdate, etc.)

  const checkMFAStatus = useCallback(async () => {
    if (user) {
      try {
        await user.reload(); // Ensure we have the latest user data
        const multiFactorUser = multiFactor(user);
        const isEnabled = multiFactorUser.enrolledFactors.length > 0;
        setIsMFAEnabled(isEnabled);
      } catch (error) {
        console.error("Error checking MFA status:", error);
        setIsMFAEnabled(false);
      }
    } else {
      setIsMFAEnabled(false);
    }
  }, [user]);

  useEffect(() => {
    checkMFAStatus();
  }, [checkMFAStatus]);

  useEffect(() => {
    const unsubscribe = apersuAuthentication.onAuthStateChanged((user) => {
      if (user) {
        checkMFAStatus();
      } else {
        setIsMFAEnabled(false);
      }
    });

    return () => unsubscribe();
  }, [checkMFAStatus]);

  const handleClose = async () => {
    setShowMFAModal(false);
    await checkMFAStatus(); // Re-check MFA status when modal closes
  };

  return (
    <div className="md:px-10">
      <ToastContainer
        limit={1}
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        enableMultiContainer
        containerId="CompanyInfoForm"
      />
      <div className="pageSubtitle text-left max-sm:ps-6 ps-10">
        Use this page to update your company information and preferences. <br />
        Fields marked{" "}
        <span className="badge badge-accent badge-xs md:badge-sm"></span> are
        required.
      </div>
      <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 my-4 md:ps-10">
        <div
          className="tooltip tooltip-bottom sm:tooltip-right"
          data-tip="Your email address has been verified, helping to confirm your identity."
        >
          <button className="btn btn-secondary no-animation btn-sm md:w-80 max-md:w-72">
            <SealCheck size={25} className="md:mr-2" />
            Email Address Verified
          </button>
        </div>

        <div
          className="tooltip tooltip-bottom sm:tooltip-right"
          data-tip={
            isMFAEnabled
              ? "Multi-factor authentication is enabled for your account."
              : "Secure your account with multi-factor authentication, requiring additional information when you log in."
          }
        >
          <button 
            className={`btn btn-sm md:w-80 max-md:w-72 ${isMFAEnabled ? 'btn-secondary' : 'btn-primary'}`}
            onClick={() => setShowMFAModal(true)}
          >
            {isMFAEnabled ? (
              <SealCheck size={25} className="md:mr-2" />
            ) : (
              <ShieldWarning size={25} className="md:mr-2" />
            )}
            Multi-Factor Authentication: {isMFAEnabled ? 'On' : 'Off'}
          </button>
        </div>
      </div>

      <div className="text-center card bg-base-100 xl:max-w-screen-2xl md:max-w-7xl md:min-w-xl mx-auto md:p-5 p-2 shadow-xl ">
       {/* FORM GOES HERE. DO NOT REMOVE THIS COMMENT */}
       <form onSubmit={handleSubmit(onSubmit)}>
<div className="grid grid-cols-2 md:grid-cols-4 md:grid-rows-20 md:gap-5 max-md:gap-1">
  {/* Company Name */}

  <div className="row-start-1 md:col-span-2 max-md:col-span-2 w-full">
    <div className="label">
      {errors.companyName && (
        <span className="label-text text-error">
          {errors.companyName.message}
        </span>
      )}
      {!errors.companyName && (
        <span className="label-text">Company Name</span>
      )}
    </div>
    <label className="input input-bordered flex items-center gap-2">
      <BuildingOffice size={20} />
      <input
        {...register("companyName", { required: "Company Name" })}
        type="text"
        className="grow"
        placeholder="Company Name"
        onChange={(e) => {
          const capitalized = capitalizeWords(e.target.value);
          e.target.value = capitalized;
          setValue("companyName", capitalized);
        }}
      />
      {!filledFields.companyName && (
        <span className="badge badge-accent badge-xs"></span>
      )}
    </label>
  </div>

  {/* Company Email */}
  <div className="md:col-span-2 max-md:col-span-2 md:col-start-3 sm:row-start-2 md:row-start-1 w-full">
    <div className="label">
      <span className="label-text">Company Email</span>
    </div>
    <div
      className="tooltip tooltip-warning w-full"
      data-tip="The Company Email Address cannot be changed."
    >
      <label className="input input-bordered flex items-center gap-2">
        <At size={20} />
        <input
          {...register("email")}
          type="email"
          className="grow"
          defaultValue={user.email}
          readOnly
        />
      </label>
    </div>
  </div>

  {/* Primary Contact Name */}
  <div className="md:row-start-2 md:col-start-1 max-md:col-span-2 sm:row-start-3 w-full">
    <div className="label">
      {errors.primaryContactName && (
        <span className="label-text text-error">
          {errors.primaryContactName.message}
        </span>
      )}
      {!errors.primaryContactName && (
        <span className="label-text">Primary Contact Name</span>
      )}
    </div>
    <label className="input input-bordered flex items-center gap-2 w-full">
      <IdentificationBadge size={20} />
      <input
        {...register("primaryContactName", {
          required: "Primary Contact Name",
        })}
        type="text"
        className="grow"
        placeholder="Jules Winnfield"
        onChange={(e) => {
          const capitalized = capitalizeWords(e.target.value);
          e.target.value = capitalized;
          setValue("primaryContactName", capitalized);
        }}
      />
      {!filledFields.primaryContactName && (
        <span className="badge badge-accent badge-xs"></span>
      )}
    </label>
  </div>
  {/* Primary Contact Phone */}
  <div className="md:row-start-2 md:col-start-2 max-md:col-span-2 sm:row-start-4 w-full md:w-full">
    <div className="label">
      {errors.primaryContactPhone && (
        <span className="label-text text-error">
          {errors.primaryContactPhone.message}
        </span>
      )}
      {!errors.primaryContactPhone && (
        <span className="label-text">Primary Contact Phone</span>
      )}
    </div>
    <label className="input input-bordered flex items-center gap-2">
      <Phone size={20} />
      <input
        {...register("primaryContactPhone", {
          required: "Primary Contact Phone",
          pattern: {
            value: /^[0-9]{11}$/,
            message: "Please enter a valid 11-digit phone number",
          },
        })}
        type="tel"
        maxLength="11"
        className="grow"
        placeholder="07123 456789"
      />
      {!filledFields.primaryContactPhone && (
        <span className="badge badge-accent badge-xs"></span>
      )}
    </label>
  </div>
  {/* Second Contact Name */}
  <div className="md:row-start-2 md:col-start-3 max-md:col-span-2 sm:row-start-5 w-full">
    <div className="label">
      <span className="label-text">Second Contact Name</span>
    </div>
    <label className="input input-bordered flex items-center gap-2 ">
      <IdentificationBadge size={20} />
      <input
        {...register("secondContactName")}
        type="text"
        className="grow"
        placeholder="Mia Wallace"
        onChange={(e) => {
          const capitalized = capitalizeWords(e.target.value);
          e.target.value = capitalized;
          setValue("secondContactName", capitalized);
        }}
      />
    </label>
  </div>

  {/* Second Contact Phone */}
  <div className="md:row-start-2 md:col-start-4 max-md:col-span-2 sm:row-start-6 w-full">
    <div className="label min-w-fit">
      <span className="label-text">Second Contact Phone</span>
    </div>
    <label className="input input-bordered flex items-center gap-2 ">
      <Phone size={20} />
      <input
        {...register("secondContactPhone", {
          pattern: {
            value: /^[0-9]{11}$/,
            message: "Please enter a valid 11-digit phone number",
          },
        })}
        type="tel"
        maxLength="11"
        className="grow"
        placeholder="07123 456789"
      />
    </label>
    {errors.secondContactPhone && (
      <span className="text-error">
        {errors.secondContactPhone.message}
      </span>
    )}
  </div>

  {/* Third Contact Name */}
  <div className="md:row-start-3 md:col-start-1 max-md:col-span-2 sm:row-start-7 w-full md:w-full">
    <div className="label">
      <span className="label-text">Third Contact Name</span>
    </div>
    <label className="input input-bordered flex items-center gap-2 w-full ">
      <IdentificationBadge size={20} />
      <input
        {...register("thirdContactName")}
        type="text"
        className="grow"
        placeholder="Vincent Vega"
        onChange={(e) => {
          const capitalized = capitalizeWords(e.target.value);
          e.target.value = capitalized;
          setValue("thirdContactName", capitalized);
        }}
      />
    </label>
  </div>

  {/* Third Contact Phone */}
  <div className="md:row-start-3 md:col-start-2 max-md:col-span-2 sm:row-start-8 w-full">
    <div className="label">
      <span className="label-text">Third Contact Phone</span>
    </div>
    <label className="input input-bordered flex items-center gap-2 w-full ">
      <Phone size={20} />
      <input
        {...register("thirdContactPhone", {
          pattern: {
            value: /^[0-9]{11}$/,
            message: "Please enter a valid 11-digit phone number",
          },
        })}
        type="tel"
        maxLength="11"
        className="grow"
        placeholder="07123 456789"
      />
    </label>
    {errors.thirdContactPhone && (
      <span className="text-error">
        {errors.thirdContactPhone.message}
      </span>
    )}
  </div>

  {/* Office */}
  <div className="md:row-start-3 md:col-start-3 max-md:col-span-2 sm:row-start-9 w-full md:w-full">
    <div className="label">
      <span className="label-text">Office</span>
    </div>
    <label className="input input-bordered flex items-center gap-2 w-full ">
      <BuildingOffice size={20} />
      <input
        {...register("officeContact")}
        type="text"
        className="grow"
        placeholder="Butch Coolidge"
        onChange={(e) => {
          const capitalized = capitalizeWords(e.target.value);
          e.target.value = capitalized;
          setValue("officeContact", capitalized);
        }}
      />
    </label>
  </div>

  {/* Office Phone */}
  <div className="md:row-start-3 md:col-start-4 max-md:col-span-2 sm:row-start-10 w-full">
    <div className="label">
      <span className="label-text">Office Phone</span>
    </div>
    <label className="input input-bordered flex items-center gap-2 w-full ">
      <Headset size={20} />
      <input
        {...register("officePhone", {
          pattern: {
            value: /^[0-9]{11}$/,
            message: "Please enter a valid 11-digit phone number",
          },
        })}
        type="tel"
        maxLength="11"
        className="grow"
        placeholder="01234 567890"
      />
    </label>
    {errors.officePhone && (
      <span className="text-error">{errors.officePhone.message}</span>
    )}
  </div>

  {/* Address */}
  <div className="md:row-start-4 md:col-start-1 sm:row-start-11 max-md:col-span-2 md:col-span-2 row-span-2 w-full">
    <div className="label">
      {errors.addressLine1 && (
        <span className="label-text text-error">
          {errors.addressLine1.message}
        </span>
      )}
      {!errors.addressLine1 && (
        <span className="label-text">Address</span>
      )}
    </div>
    <label className="input input-bordered flex items-center gap-2 w-full">
      <input
        {...register("addressLine1", {
          required: "Address line 1 is required",
        })}
        type="text"
        className="grow"
        placeholder="Address Line 1"
        onChange={(e) => {
          const capitalized = capitalizeWords(e.target.value);
          e.target.value = capitalized;
          setValue("addressLine1", capitalized);
        }}
      />
      {!filledFields.addressLine1 && (
        <span className="badge badge-accent badge-xs"></span>
      )}
    </label>
    <label className="input input-bordered flex items-center gap-2 w-full">
      <input
        {...register("addressLine2")}
        type="text"
        className="grow"
        placeholder="Address Line 2"
        onChange={(e) => {
          const capitalized = capitalizeWords(e.target.value);
          e.target.value = capitalized;
          setValue("addressLine2", capitalized);
        }}
      />
    </label>
    <label className="input input-bordered flex items-center gap-2 w-full">
      <input
        {...register("addressLine3")}
        type="text"
        className="grow"
        placeholder="Address Line 3"
        onChange={(e) => {
          const capitalized = capitalizeWords(e.target.value);
          e.target.value = capitalized;
          setValue("addressLine3", capitalized);
        }}
      />
    </label>

    <label className="input input-bordered flex items-center gap-2 w-full ">
      <input
        {...register("postCode", {
          required: "Post code is required",
          pattern: {
            value:
              /^[A-Z]{1,2}[0-9][A-Z0-9]? [0-9][ABD-HJLNP-UW-Z]{2}$/,
            message: "Please enter a valid UK post code",
          },
        })}
        type="text"
        className="grow"
        placeholder="Post Code"
        onChange={(e) => {
          const upperCase = e.target.value.toUpperCase();
          e.target.value = upperCase;
          setValue("postCode", upperCase);
        }}
      />
      {!filledFields.postCode && (
        <span className="badge badge-accent badge-xs"></span>
      )}
    </label>
    <div className="label">
      {errors.postCode && (
        <span className="label-text text-error">
          {errors.postCode.message}
        </span>
      )}
    </div>
  </div>

  {/* VAT Number */}
  <div className="md:col-start-3 md:row-start-4 sm:row-start-13 max-sm:col-span-2 pb-0 w-full md:w-full">
    <div className="label">
      <span className="label-text">VAT Number</span>
    </div>
    <label className="input input-bordered flex items-center gap-1 w-full md:w-full">
      GB
      <input
        {...register("vatNumber", {
          pattern: {
            value: /^[0-9]{9}$/,
            message: "Please enter a valid 9-digit VAT number",
          },
        })}
        type="tel"
        maxLength="9"
        className="grow"
        placeholder="123456789"
      />
    </label>
    {errors.vatNumber && (
      <span className="text-error">{errors.vatNumber.message}</span>
    )}
  </div>
  {/* VAT Number */}
  <div className="md:col-start-4 md:row-start-4 sm:row-start-13 max-sm:col-span-2 pb-0 w-full md:w-full">
    <div className="label">
      <span className="label-text">CRN Number</span>
    </div>
    <label className="input input-bordered flex items-center gap-1 w-full md:w-full">
      <input
        {...register("CRNNumber")}
        type="tel"
        maxLength="8"
        className="grow"
        placeholder="12345678"
      />
    </label>
    {errors.CRNNumber && (
      <span className="text-error">{errors.CRNNumber.message}</span>
    )}
  </div>

  {errors.postCode && (
    <>
      {/* Contingency Margin */}
      <div className="md:col-start-3 md:row-start-5 sm:row-start-15 sm:col-start-1 w-full md:w-full">
        <div className="label md:mt-0">
          <span className="xl:hidden label-text">
            Contingency Margin
            {errors.contingencyMargin && (
              <span className="text-error">
                {errors.contingencyMargin.message}
              </span>
            )}
          </span>
          <span className="max-xl:hidden label-text">
            Preferred Contingency Margin
            {errors.contingencyMargin && (
              <span className="text-error">
                {errors.contingencyMargin.message}
              </span>
            )}
          </span>
          <span className="label-text ">
            <div
              className="tooltip tooltip-right"
              data-tip="Contingency margin explanation."
            >
              <Info size={22} color="#137000" weight="fill" />
            </div>
          </span>
        </div>
        <label className="input input-bordered flex items-center gap-2 max-md:w-full">
          <input
            {...register("contingencyMargin", {
              required: " - required.",
              pattern: {
                value: /^[0-9]{1,2}$/,
                message: " - 0-99%",
              },
            })}
            type="tel"
            maxLength="2"
            className="text-right w-4/5"
            placeholder="Contingency Margin"
          />
          <Percent size={20} />
        </label>
      </div>

      {/* Profit Margin */}
      <div className="md:col-start-4 md:row-start-5 sm:row-start-15 sm:col-start-2 w-full md:w-full">
        <div className="label md:mt-0">
          <span className="xl:hidden label-text">
            Profit Margin
            {errors.profitMargin && (
              <span className="text-error">
                {errors.profitMargin.message}
              </span>
            )}
          </span>
          <span className="max-xl:hidden label-text ">
            Preferred Profit Margin
            {errors.profitMargin && (
              <span className="text-error">
                {errors.profitMargin.message}
              </span>
            )}
          </span>
          <span className="label-text ">
            <div
              className="tooltip tooltip-left place-self-center"
              data-tip="Profit margin explanation."
            >
              <Info size={22} color="#137000" weight="fill" />
            </div>
          </span>
        </div>
        <label className="input input-bordered flex items-center gap-2 max-md:w-full">
          <input
            {...register("profitMargin", {
              required: " - required.",
              pattern: {
                value: /^[0-9]{1,2}$/,
                message: " - 0-99%",
              },
            })}
            type="tel"
            maxLength="2"
            className="text-right w-4/5"
            placeholder="Profit Margin"
          />
          <Percent size={20} />
        </label>
      </div>
    </>
  )}
  {!errors.postCode && (
    <>
      {/* Contingency Margin */}
      <div className="md:col-start-3 md:row-start-5 sm:row-start-15 sm:col-start-1 w-full md:w-full">
        <div className="label md:mt-5">
          <span className="xl:hidden label-text">
            Contingency
            {errors.contingencyMargin && (
              <span className="text-error">
                {errors.contingencyMargin.message}
              </span>
            )}
          </span>
          <span className="max-xl:hidden label-text">
            Preferred Contingency Margin
            {errors.contingencyMargin && (
              <span className="text-error">
                {errors.contingencyMargin.message}
              </span>
            )}
          </span>
          <span className="label-text ">
            <div
              className="tooltip tooltip-right"
              data-tip="Contingency margin explanation."
            >
              <Info size={22} color="#137000" weight="fill" />
            </div>
          </span>
        </div>
        <label className="input input-bordered flex items-center gap-2 max-md:w-full">
          <input
            {...register("contingencyMargin", {
              required: " - required.",
              pattern: {
                value: /^[0-9]{1,2}$/,
                message: " - 0-99%",
              },
            })}
            type="tel"
            maxLength="2"
            className="text-right w-4/5"
            placeholder="Contingency Margin"
          />
          <Percent size={20} />
        </label>
      </div>

      {/* Profit Margin */}
      <div className="md:col-start-4 md:row-start-5 sm:row-start-15 sm:col-start-2 w-full md:w-full">
        <div className="label md:mt-5">
          <span className="xl:hidden label-text">
            Profit Margin
            {errors.profitMargin && (
              <span className="text-error">
                {errors.profitMargin.message}
              </span>
            )}
          </span>
          <span className="max-xl:hidden label-text ">
            Preferred Profit Margin
            {errors.profitMargin && (
              <span className="text-error">
                {errors.profitMargin.message}
              </span>
            )}
          </span>
          <span className="label-text ">
            <div
              className="tooltip tooltip-left place-self-center"
              data-tip="Profit margin explanation."
            >
              <Info size={22} color="#137000" weight="fill" />
            </div>
          </span>
        </div>
        <label className="input input-bordered flex items-center gap-2 max-md:w-full">
          <input
            {...register("profitMargin", {
              required: " - required.",
              pattern: {
                value: /^[0-9]{1,2}$/,
                message: " - 0-99%",
              },
            })}
            type="tel"
            maxLength="2"
            className="text-right w-4/5"
            placeholder="Profit Margin"
          />
          <Percent size={20} />
        </label>
      </div>
    </>
  )}

  {/* Preferences */}
  <div className="md:col-span-4 max-md:col-span-2 row-span-3 md:row-start-6">
    <label className="form-control">
      <div className="label">
        <span className="label-text">Your Preferences</span>
      </div>
      <textarea
        {...register("customerPreferences")}
        className="textarea textarea-bordered h-24"
        placeholder="Use this area to make notes for us to use in all future estimates..."
      ></textarea>
    </label>
    <br />
    <button
      className="btn btn-secondary btn-wide mb-0"
      type="submit"
      disabled={isLoading}
    >
      <CheckCircle size={25} />
      {isLoading ? "Updating..." : "Update Information"}
    </button>
  </div>
</div>
</form>
      </div>

      {/* MFA Modal */}
      {showMFAModal && user && (
        <dialog id="mfa_modal" className="modal modal-open">
          <div className="modal-box shadow-xl border-primary bordered border-4">
            <MFAManager 
              onClose={handleClose} 
              isMFAEnabled={isMFAEnabled} 
              setIsMFAEnabled={setIsMFAEnabled} 
            />
          </div>
          <form method="dialog" className="modal-backdrop">
            <button onClick={handleClose}>close</button>
          </form>
        </dialog>
      )}
    </div>
  );
}