import XLCards from "../../components/DashboardCards/XLCards";
import LGCards from "../../components/DashboardCards/LGCards";
import MDCards from "../../components/DashboardCards/MDCards";
import SMCards from "../../components/DashboardCards/SMCards";

export default function Dashboard() {
  return (
    <>
    <div className="bg-base-100 md:px-10 px-3 my-0">
        <div className="divider divider-primary mb-0"><p className="pageTitle">Dashboard</p></div>
    <XLCards/>
    <LGCards/>
    <MDCards/>
    <SMCards/>

    </div>
    </>
  )
}
