import { CurrencyGbp, Files, UserRectangle, Wrench } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'
export default function SMCards() {
  return (<>
        <div className=" sm:hidden grid grid-cols-1 grid-rows-4 gap-8">
        <Link to="/MyProfile">
<div className="card card-compact bg-base-200 hover:bg-base-100 hover:ring hover:ring-primary w-full shadow-xl">
  <figure>
    <img
      src="https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp"
      alt="Shoes" />
  </figure>
  <div className="card-body">
    <h2 className="cardTitle divider divider-start divider-primary">My Profile</h2><br/>
    <p className="smcardText">
    View and adjust your profile information.
    </p><br/>
    <div className="card-actions justify-end">
    <button className="btn w-56 btn-primary"><UserRectangle size={25}/>My Profile</button>
    </div>
  </div>
</div></Link>
<Link to="MyEstimates">
<div className="card card-compact bg-base-200 hover:bg-base-100 hover:ring hover:ring-primary w-full shadow-xl">
  <figure>
    <img
      src="https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp"
      alt="Shoes" />
  </figure>
  <div className="card-body">
    <h2 className="cardTitle divider divider-start divider-primary">My Estimates</h2><br/>
    <p className="smcardText">View your estimates and submit new projects.</p><br/>
    <div className="card-actions justify-end">
    <button className="btn w-56 btn-primary"><Files size={25}/>My Estimates</button>
    </div>
  </div>
</div></Link>
<Link to="LabourRates">
<div className="card card-compact bg-base-200 hover:bg-base-100 hover:ring hover:ring-primary w-full shadow-xl">
  <figure>
    <img
      src="https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp"
      alt="Shoes" />
  </figure>
  <div className="card-body">
    <h2 className="cardTitle divider divider-start divider-primary">Labour Rates</h2><br/>
    <p className="smcardText">View and adjust labour rates.</p><br/>
    <div className="card-actions justify-end">
    <button className="btn w-56 btn-primary"><Wrench size={25}/>Adjust Labour Rates</button>
    </div>
  </div>
</div></Link>
<Link to="MaterialCosts">
<div className="card card-compact bg-base-200 hover:bg-base-100 hover:ring hover:ring-primary w-full shadow-xl">
  <figure>
    <img
      src="https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp"
      alt="Shoes" />
  </figure>
  <div className="card-body">
    <h2 className="cardTitle divider divider-start divider-primary">Material Costs</h2><br/>
    <p className="smcardText">View and adjust material costs.</p><br/>
    <div className="card-actions justify-end">
    <button className="btn w-56 btn-primary"><CurrencyGbp size={25}/>Adjust Material Costs</button>
    </div>
  </div>
</div></Link>
</div>
    <br/><br/>
 </> )
}
