import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useLogin } from "../../hooks/useLogin";
import ForgotPassword from "./ForgotPassword";
import LoadingButton from "../../components/Buttons/LoadingButton";
import LoginButton from "../../components/Buttons/LoginButton";
import {
  Eye,
  EyeSlash,
  At,
  Password,
  PencilLine,
  Phone,
  XCircle
} from "@phosphor-icons/react";
import { 
  multiFactor, 
  PhoneAuthProvider, 
  PhoneMultiFactorGenerator, 
  getMultiFactorResolver
} from "firebase/auth";
import { apersuAuthentication } from "../../firebase/config";

const errorMessages = {
  EMPTY_FIELDS: "Please fill in both email and password.",
  INVALID_CREDENTIALS: "Invalid email or password. Please check and try again.",
  WRONG_PASSWORD: "Incorrect password. Please try again.",
  INVALID_EMAIL: "Invalid email format. Please check your email.",
  USER_DISABLED: "This account has been disabled. Please contact support.",
  TOO_MANY_REQUESTS: "Too many failed login attempts. Please try again later.",
  NETWORK_ERROR: "Network error. Please check your internet connection and try again.",
  DEFAULT: "An error occurred. Please try again.",
  MFA_REQUIRED: "Multi-Factor Authentication is required. Please enter the verification code sent to your phone.",
};

export default function LoginForm() {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState("");
  const [mfaVerificationId, setMfaVerificationId] = useState("");
  const [isMfaRequired, setIsMfaRequired] = useState(false);
  const [resolver, setResolver] = useState(null);
  const [showMFAModal, setShowMFAModal] = useState(false);
  const [mfaVerificationCode, setMfaVerificationCode] = useState(['', '', '', '', '', '']);

  const { login, error: loginError, isPending, clearError } = useLogin();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setFormError("");
      console.log("Login form submitted for email:", email);
      try {
        await login(email, password);
      } catch (error) {
        if (error.code === 'auth/multi-factor-auth-required') {
          setIsMfaRequired(true);
          const multiFactorResolver = getMultiFactorResolver(apersuAuthentication, error);
          setResolver(multiFactorResolver);
          
          const phoneInfoOptions = {
            multiFactorHint: multiFactorResolver.hints[0],
            session: multiFactorResolver.session
          };
          const phoneProvider = new PhoneAuthProvider(apersuAuthentication);
          const verificationId = await phoneProvider.verifyPhoneNumber(phoneInfoOptions);
          setMfaVerificationId(verificationId);
          setShowMFAModal(true);
        } else {
          setFormError(errorMessages[error.code] || errorMessages.DEFAULT);
        }
      }
    },
    [email, password, login]
  );

  const handleMFASubmit = async (e) => {
    e.preventDefault();
    try {
      const cred = PhoneAuthProvider.credential(mfaVerificationId, mfaVerificationCode.join(''));
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      const userCredential = await resolver.resolveSignIn(multiFactorAssertion);
      console.log("MFA sign-in successful", userCredential.user);
      setShowMFAModal(false);
      // Handle successful login (e.g., redirect to dashboard)
    } catch (error) {
      console.error("MFA verification failed", error);
      setFormError(errorMessages.INVALID_CREDENTIALS);
    }
  };

  const handleMFACodeChange = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newCode = [...mfaVerificationCode];
      newCode[index] = value;
      setMfaVerificationCode(newCode);
      
      if (value !== '' && index < 5) {
        document.getElementById(`mfa-code-${index + 1}`).focus();
      }
    }
  };

  const handleMFAKeyDown = (index, e) => {
    if (e.key === 'Backspace' && index > 0 && mfaVerificationCode[index] === '') {
      document.getElementById(`mfa-code-${index - 1}`).focus();
    }
  };

  const handleEmailChange = useCallback(
    (e) => {
      setEmail(e.target.value);
      setFormError("");
      clearError();
      console.log("Email input changed");
    },
    [clearError]
  );

  const handlePasswordChange = useCallback(
    (e) => {
      setPassword(e.target.value);
      setFormError("");
      clearError();
      console.log("Password input changed");
    },
    [clearError]
  );

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  useEffect(() => {
    if (loginError) {
      console.log("Login error received in form:", loginError);
      if (loginError === "WRONG_PASSWORD") {
        console.log("Setting specific wrong password error message");
        setFormError("The email is correct, but the password is incorrect. Please try again.");
      } else {
        console.log("Setting general error message for:", loginError);
        setFormError(errorMessages[loginError] || errorMessages.DEFAULT);
      }
    }
  }, [loginError]);

  useEffect(() => {
    console.log("Login form rendered. isPending:", isPending, "error:", loginError);
  }, [isPending, loginError]);

  return ( 
    <div className="text-center lg:text-left hero sm:min-h-full bg-base-100">
      <div className="hero-content flex-col lg:flex-row-reverse">
        <div className="pl-0 lg:pl-10 lg:w-1/2 lg:self-start lg:sticky lg:top-8 lg:mt-20">
          <div className="divider lg:divider-start divider-primary pageTitleSm">
            Log In
          </div>
          <p className="sm:py-5 w-full max-w-md">
            Please enter your email address and password here. If you don't have
            an account, please sign up using the button below.
          </p>
          <div className="sm:hidden">
            <br />
          </div>
          <Link to="/Signup">
            <button className="btn btn-wide btn-secondary">
              <PencilLine size={25} />
              Create an Account
            </button>
          </Link>
        </div>
        <div className="card shrink-0 w-full max-w-md shadow-2xl bg-base-200" style={{width: '448px'}}>
          {!isMfaRequired ? (
            <form className="card-body" onSubmit={handleSubmit}>
              <div className="form-control pb-3">
                <label className="input input-bordered flex items-center gap-2">
                  <At size={25} />
                  <input
                    type="email"
                    className="grow"
                    placeholder="Email"
                    id="1email"
                    onChange={handleEmailChange}
                    value={email}
                  />
                </label>
              </div>
              <div className="form-control">
                <label className="input input-bordered flex items-center gap-2 pe-0">
                  <Password size={25} />
                  <input
                    type={showPassword ? "text" : "password"}
                    className="grow"
                    placeholder="Password"
                    id="1password"
                    onChange={handlePasswordChange}
                    value={password}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="btn btn-link text-base-content">
                    {showPassword ? <EyeSlash size={25} /> : <Eye size={25} />}
                  </button>
                </label>
                <button
                  type="button"
                  className="btn btn-secondary btn-sm w-auto mt-2"
                  onClick={() => setShowForgotPassword(true)}>
                  Forgot Password?
                </button>
              </div>
              <div className="form-control mt-14 ">
                {!isPending ? (
                  <LoginButton type="submit" />
                ) : (
                  <LoadingButton />
                )}
              </div>
            </form>
          ) : (
            <form className="card-body" onSubmit={handleMFASubmit}>
            <div className="form-control">
              <label className="input input-bordered flex items-center gap-2">
                <Phone size={25} />
                <input
                  type="text"
                  className="grow"
                  placeholder="Verification Code"
                  onChange={(e) => setMfaVerificationCode(e.target.value)}
                  value={mfaVerificationCode}
                />
              </label>
            </div>
            <div className="form-control mt-6">
              <button type="submit" className="btn btn-primary">Verify</button>
            </div>
          </form>
          )}
          {formError && (
            <div className="alert alert-error mt-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>{formError}</span>
            </div>
          )}
        </div>
      </div>
      {showForgotPassword && (
        <ForgotPassword onClose={() => setShowForgotPassword(false)} />
      )}
            {/* MFA Modal */}
            {showMFAModal && (
        <dialog open className="modal">
          <div className="modal-box shadow-xl border-secondary bordered border-4">
            <button 
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
              onClick={() => setShowMFAModal(false)}
            >
              <XCircle size={30}/>
            </button>
            <h3 className="font-bold pageSubtitle text-lg">2FA Verification</h3>
            <div className="py-4">
              <form onSubmit={handleMFASubmit}>
                <div className="verification-code-container">
                  {mfaVerificationCode.map((digit, index) => (
                    <input
                      key={index}
                      id={`mfa-code-${index}`}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleMFACodeChange(index, e.target.value)}
                      onKeyDown={(e) => handleMFAKeyDown(index, e)}
                    />
                  ))}
                </div>
                <div className="form-control mt-6">
                  <button type="submit" className="btn btn-primary">Verify</button>
                </div>
              </form>
            </div>
          </div>
          <form method="dialog" className="modal-backdrop">
            <button onClick={() => setShowMFAModal(false)}>close</button>
          </form>
        </dialog>
      )}
    </div>
  );
}